import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { LayoutComponent } from '../layout.component';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<LayoutComponent>, @Inject(MAT_DIALOG_DATA) public user: any, public dialog: MatDialog,) {
    this.dialogRef.disableClose = true;
    
  }

  ngOnInit() {
    
  }
  close(): void {
    this.dialogRef.close();
  }


}
