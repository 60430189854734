import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (localStorage.getItem('login_user_info')) {
      return true;
    } else {
      return true;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class FiservRouteGuard implements CanActivate {
  constructor(private router: Router) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (localStorage.getItem('login_user_info')) {
      let userinfo = JSON.parse(localStorage.getItem('login_user_info'));
      if (userinfo.courseData.enable_cardconnect == 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }

  }
}
