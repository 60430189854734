import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA , MatDialog } from '@angular/material';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { AlertService } from '../../../../shared/services/alert.service';
import { DashboardService } from '../../../../analytics/dashboard/dashboard.service';
import { Router } from '@angular/router';
import { emailValidator, matchingPasswords } from '../../../utils/app-validators';
import { LoginService } from '../../../../logins/login.service';
import { Location, DatePipe } from '@angular/common';
import { MustMatch } from '../../../../shared/must-match.validator';
//import { MailboxComponent } from '../../../../mailbox/mailbox.component';
import { ColorPickerService } from 'ngx-color-picker'; // Color picker
import { HttpClient } from '@angular/common/http';
import { jsPDF } from "jspdf";
import { exit } from 'process';

//var be_server: string = 'http://localhost:6533/'; // local
var be_server: string = 'https://dev.9-eighteen.com/api/v1/'; // dev
//var be_server: string = 'https://qa.9-eighteen.com/api/v1/'; // qa

@Component({
  selector: 'app-profile-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.scss']
})

export class ProfileDialogComponent implements OnInit {
  isOneLetter = false;
  isOneCapitalLetter = false;
  isOneNumber = false;
  isOneSpecialCaharacter = false;
  isMinLength = false;
  isValidate = false;
  addUserForm: FormGroup;
  courseAddressForm: FormGroup;
  courseOwnershipForm: FormGroup;
  owners: FormArray;
  courseBankForm: FormGroup;
  menuLocations: FormGroup;
  taxForm: FormGroup;
  webAppForm: FormGroup;
  provinces: any;
  cities: any;
  repCities: any;
  repProvinces : any;
  ownersProvinces = {};
  ownersCities = {};
  countries: any;
  bankProvinces : any;
  bankCities : any;
  provinceId: any;
  isValidZipcode = 0;
  showCancel = true;
  zipCords : any = [];
  message:String;
  userType = 'admin';
  canadaProvinces = [{id:1,province:"Alberta", shortCode : "AB"},{id:2,province:"British Columbia", shortCode : "BC"},{id:3,province:"Manitoba", shortCode : "MB"},{id:4,province:"New Brunswick", shortCode : "NB"},{id:5,province:"Newfoundland and Labrador", shortCode : "NL"},{id:6,province:"Nova Scotia", shortCode : "NS"},{id:7,province:"Ontario", shortCode : "ON"},{id:8,province:"Prince Edward Island", shortCode : "PE"},{id:9,province:"Quebec", shortCode : "QC"},{id:10,province:"Saskatchewan", shortCode : "SK"},{id:11,province:"Yukon", shortCode : "YT"},{id:12,province:"Nunavut", shortCode : "NU"},{id:13,province:"Northwest Territories", shortCode : "NT"}];
  //formObject = [{id:0,length:"One"},{id:1,length:"Two"},{id:2,length:"Three"}]
  classes = [{id:"PERSONAL",name:"Personal"},{id:"CORPORATE",name:"Corporate"},{id:"INTERNATIONAL",name:"International"} ]
  types = [{id:"CHECKING",name:"Checking"},{id:"SAVINGS",name:"Savings"}];
  /*
  currencies = [{id:"AUD",name:"Australian Dollar"},{id:"CAD",name:"Canadian Dollar"}, {id:"CHF",name:"Swiss Franc"}, {id:"CZK",name:"Czech Koruna"}, {id:"DKK",name:"Danish Krone"}, {id:"EUR",name:"Euro"}, {id:"GBP",name:"British Pound Sterling"}, {id:"HKD",name:"Hong Kong Dollar"}, {id:"HUF",name:"Hungarian Forint"}, {id:"JPY",name:"Japanese Yen"}, {id:"NGN",name:"Nigerian Naira"}, {id:"NOK",name:"Norwegian Krone"}, {id:"NZD",name:"New Zealand Dollar"}, {id:"PLN",name:"Polish Złoty"}, {id:"SEK",name:"Swedish Krona"}, {id:"SGD",name:"Singapore Dollar"}, {id:"USD",name:"United States Dollar"}, {id:"ZAR",name:"South African Rand"}]; 
  */

  currencies = [{id:"CAD",name:"Canadian Dollar"}, {id:"USD",name:"United States Dollar"}];
  businessTypes = [{id:"Limited Liability",name:"Limited Liability"},{id:"Non-profit",name:"Non-profit"}, {id:"Partnership",name:"Partnership"}, {id:"Private Corporation",name:"Private Corporation"}, {id:"Public Corporation",name:"Public Corporation"}, {id:"Sole Proprietorship",name:"Sole Proprietorship"}];
  businessCategorys = [{id:"Business Services",name:"Business Services"},{id:"Non-profit",name:"Non-profit"}, {id:"Education",name:"Education"}, {id:"Personal Services",name:"Personal Services"}, {id:"Pharmacy",name:"Pharmacy"}, {id:"Physical Goods",name:"Physical Goods"}, {id:"Software",name:"Software"}, {id:"Travel",name:"Travel"}, {id:"Other",name:"Other"}];
  currDate = new Date();
  public maxDate = new Date(this.currDate.getFullYear(), this.currDate.getMonth(), this.currDate.getDate());
  clientIP:string;
  showAddOwner = false;

  uploadPath = be_server+'uploads/course/';
  uploadedLogo = '';

  formObject = [{id:0,length:"location"},{id:1,length:"Two"},{id:2,length:"Three"}]
  tableList: any;

  courseLocations: FormArray;

  public presetValues : string[] = [];
  public selectedBgColor: string = '';
  public selectedTextColor: string = '';
  public CourseQRCode = '';
  public timeOut = '';
  public lightspeed_Status : any;
  public time_zone = this.getTimeZone();
  public isTruGolfCourse : any;

  public editorConfig = {
    "editable": true,
    "spellcheck": true,
    "height": "300px",
    "minHeight": "300px",
    "width": "auto",
    "minWidth": "0",
    "translate": "yes",
    "enableToolbar": true,
    "showToolbar": true,
    "placeholder": "Disclaimer information",
    "imageEndPoint": "",
    "toolbar": [
        ["bold", "italic", "underline", "strikeThrough", "superscript", "subscript"],
        ["fontName", "fontSize", "color"],
        ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull", "indent", "outdent"],
        ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
        ["paragraph", "blockquote", "removeBlockquote", "horizontalLine", "orderedList", "unorderedList"],
        ["link", "unlink"]
    ]
  };
//End of color Picker

  onKeyPress(e) {
    var pswd = e.value;
    //validate the length
    if (pswd.length < 8) {
      this.isMinLength = false;
    } else {
      this.isMinLength = true;
    }

    //validate letter
    if (pswd.match(/[A-z]/)) {
      if (pswd != '_') {
        this.isOneLetter = true;
      } else {
        this.isOneLetter = false;
      }
    } else {
      this.isOneLetter = false;
    }

    //validate uppercase letter
    if (pswd.match(/[A-Z]/)) {
      this.isOneCapitalLetter = true;
    } else {
      this.isOneCapitalLetter = false;
    }

    //validate special character
    if (pswd.match(/[!@#\$%\^&\_\+\<\>\.\,\=\:\;\'\?\(\)\[\]\\\/\|\*{}-]/)) {
      this.isOneSpecialCaharacter = true;
    } else {
      this.isOneSpecialCaharacter = false;
    }

    //validate number
    if (pswd.match(/\d/)) {
      this.isOneNumber = true;
    } else {
      this.isOneNumber = false;
    }

  }

  
  cheklightspeedStatus() {
    if(this.addUserForm.value.lightspeedStatus == 1) {
      this.addUserForm.controls['lightspeedType'].disable();
    }
    else {
      this.addUserForm.controls['lightspeedType'].enable();
    }
    this.lightspeed_Status = (this.addUserForm.value.lightspeedStatus?0:1);
  }

  triggerSomeEventOne() {
    if(!this.menuLocations.value.locationOneCheck) {
      this.menuLocations.controls['locationOne'].disable();
    }
    else {
      this.menuLocations.controls['locationOne'].enable();
    }
  }
  
  triggerSomeEventTwo() {
    if(!this.menuLocations.value.locationTwoCheck) {
      this.menuLocations.controls['locationTwo'].disable();
    }
    else {
      this.menuLocations.controls['locationTwo'].enable();
    }
  }
  
  triggerSomeEventThree() {
    if(!this.menuLocations.value.locationThreeCheck) {
      this.menuLocations.controls['locationThree'].disable();
    }
    else {
      this.menuLocations.controls['locationThree'].enable();
    }
  }
  
  triggertaxCheckOne() {
    if(!this.taxForm.value.gstCheck) {
      this.taxForm.controls['gstValue'].disable();
    }
    else {
      this.taxForm.controls['gstValue'].enable();
    }
  }
  
  triggertaxCheckTwo() {
    if(!this.taxForm.value.pstCheck) {
      this.taxForm.controls['pstValue'].disable();
    }
    else {
      this.taxForm.controls['pstValue'].enable();
    }
  }
  
  triggertaxCheckThree() {
    if(!this.taxForm.value.hstCheck) {
      this.taxForm.controls['hstValue'].disable();
    }
    else {
      this.taxForm.controls['hstValue'].enable();
    }
  }

  triggertaxCheckFour() {
    if(!this.taxForm.value.liquorCheck) {
      this.taxForm.controls['liquorValue'].disable();
    }
    else {
      this.taxForm.controls['liquorValue'].enable();
    }
  }
  
  // triggertaxCheckFour() {
  //   if(!this.taxForm.value.liquorCheck) {
  //     this.taxForm.controls['liquorValue'].disable();
  //   }
  //   else {
  //     this.taxForm.controls['liqourValue'].enable();
  //   }
  // }
  
  constructor(public datepipe: DatePipe, public dashboardService : DashboardService,public dialogRef: MatDialogRef<ProfileDialogComponent>, @Inject(MAT_DIALOG_DATA) public course: any,private alertService: AlertService,public fb : FormBuilder
  , private cpService: ColorPickerService, private http : HttpClient
  ) {
    this.dialogRef.disableClose = true;
    this.createUserForm();
    this.createOwnershipForm();
    this.createCourseForm();
    this.createBankForm();
    this.createMenuLocationsForm();
    this.createTaxesForm();
    this.createWebAppForm();
    if(this.course) {
      this.showCancel = this.course.canCancel ? true : false;
      this.getCourseData();
    } else {
      this.getCountriesDropdown({});
      this.populateData();
    }

  }
  
    public onTextEventLog(event: string, data: any): void {
      if(event)
      this.webAppForm.controls['golfCourseTextColor'].setValue(event);
    }
    public onBgEventLog(event: string, data: any): void {
      if(event)
      this.webAppForm.controls['golfCourseBgColor'].setValue(event);
    }
  getCourseData() {
    if(!this.course.courseId)
      return;
    this.dashboardService.getCourses({"courseId":this.course.courseId}).then(data => {
      if(data.success) {
        this.course = data.results[0];
        this.getCountriesDropdown({});
        if(this.course.tbl_admin_users[0].country) {
          this.getProvincesDropdown(this.course.tbl_admin_users[0].country, 1);
          this.getCitiesDropdown(this.course.tbl_admin_users[0].state, 1, 0);
        } else {
          this.populateData();
        }
        if(this.course && this.course.tbl_course_bluesnap_details.length) {
          this.getProvincesDropdown(this.course.tbl_course_bluesnap_details[0].country, 3);
          this.getCitiesDropdown(this.course.tbl_course_bluesnap_details[0].state, 2, 0);
        }
        if(this.course && this.course.tbl_course_owners.length) {
          for(let i=0; i< this.course.tbl_course_owners.length; i++) {
            if(this.course.tbl_course_owners[i].isOwner) {
              this.getProvincesDropdown(this.course.tbl_course_owners[i].ownerCountry, i ? i+3 : i+4);
              this.getCitiesDropdown(this.course.tbl_course_owners[i].ownerState, i ? i+4 : i+5, 0);
            } else {
              this.getProvincesDropdown(this.course.tbl_course_owners[i].ownerCountry, 2);
              this.getCitiesDropdown(this.course.tbl_course_owners[i].ownerState, 4, 0);
            }
          }
        }
      }
      else {
        this.course = {};
        this.alertService.createAlert(data.message,0);
      }
    })
  }
  
  createUserForm() {
      
      this.addUserForm = this.fb.group({
        addCourseName : new FormControl('', [Validators.required]),
        legalName : new FormControl(''),
        productAndServiceDesc : new FormControl(''),
        businessType : new FormControl(''),
        businessCategory : new FormControl(''),
        taxId : new FormControl(''),
        salesVolume : new FormControl(''),
        businessRegistrationNumber : new FormControl(''),
        firstName : new FormControl('', [Validators.required]),
        /* lastName : new FormControl('', [Validators.required]), */
        addContact : new FormControl('', [Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
        addEmail : new FormControl('', [Validators.required]),
        lightspeedStatus : new FormControl('', [Validators.required]),
        lightspeedType : new FormControl('', this.lightspeed_Status ? Validators.required : []),
        stripeAccountID : new FormControl(''),
        membershipStatus : new FormControl(''),
        numberofLocations : new FormControl(''),
        orderSummary : new FormControl(''),
         specific_location : new FormControl(''),
         specific_location_choice : new FormControl(''),
        editorContent : new FormControl(''),
//        qrCode : new FormControl(''),
      })
  }
  
  createCourseForm() {
    this.courseAddressForm = this.fb.group({
      country : new FormControl('', [Validators.required]),
      province : new FormControl('', [Validators.required]),
      city : new FormControl('', [Validators.required]),
      courseAddress : new FormControl('', [Validators.required]),
      postal : new FormControl('', [Validators.required]),
      latitude : new FormControl('', [Validators.required]),
      longitude : new FormControl('', [Validators.required]),
      radius : new FormControl('', [Validators.required]),
      course_time_zone : new FormControl('', [Validators.required]),
      url : new FormControl(''),
    })
  }

  createOwnershipForm() {
    this.courseOwnershipForm = this.fb.group({
      "isOwnerExists": new FormControl(false),
      "owners" : this.fb.array([])
      //this.fb.array([this.createOwner(type)])
    });
  }

  createOwner(type, value) {
    type = value ? value.isOwner : type;
    return this.fb.group({
      id : new FormControl(value ? value.id : ''),
      ownerFirstName : new FormControl(value ? value.ownerFirstName : ''),
      ownerLastName : new FormControl(value ? value.ownerLastName : ''),
      ownerPhone : new FormControl(value ? value.ownerPhone : ''),
      ownerDateOfBirth : new FormControl(value ? value.ownerDateOfBirth : ''),
      ownerGovID : new FormControl(value ? value.ownerGovID : ''),
      ownerCountry : new FormControl(value ? value.ownerCountry : ''),
      ownerState : new FormControl(value ? value.ownerState : ''),
      ownerCity : new FormControl(value ? value.ownerCity : ''),
      ownerAddress : new FormControl(value ? value.ownerAddress : ''),
      ownerZip : new FormControl(value ? value.ownerZip : ''),
      isOwner : new FormControl(type),
    })
  }

  addOwner(type) {
    if(this.showAddOwner) {
      this.owners = this.courseOwnershipForm.get('owners') as FormArray;
      this.owners.push(this.createOwner(type, null));
    }
  }

  isOwnerExists() {
    console.log(this.courseOwnershipForm.value.isOwnerExists);
    if(!this.courseOwnershipForm.value.isOwnerExists) {
      this.showAddOwner = false;
      this.owners = this.courseOwnershipForm.get('owners') as FormArray;
      let i = this.owners.length;
      while( i >= 1) {
        this.removeOwner(i);
        i--;
      }
      console.log(this.owners);
    }
    else {
      this.showAddOwner = true;
      this.addOwner(1);
    }
  }

  removeOwner(index) {
    this.owners = this.courseOwnershipForm.get('owners') as FormArray;
    if (this.owners.length > 1) {
      this.owners.removeAt(index);
      if(this.owners.length <= 1) {
        this.showAddOwner = false;
        this.courseOwnershipForm.controls['isOwnerExists'].setValue(false);
      }
    } else {
      this.owners.reset();
    }
  }

  AddRowWithData(value: any) {
    this.owners = this.courseOwnershipForm.get('owners') as FormArray;
    this.owners.push(this.createOwner(0, value));
  }
  AddRowWithDataMenu(value: any) {
    this.courseLocations = this.menuLocations.get('courseLocations') as FormArray;
    this.courseLocations.push(this.createMenu(value));
  }
  

  disableInputs() {
    (<FormArray>this.courseOwnershipForm.get('owners'))
      .controls
      .forEach(control => {
        control.disable();
      })
  }

  createBankForm() {
    this.courseBankForm = this.fb.group({
      bankName : new FormControl(''),
      bankCountry : new FormControl(''),
      bankProvince : new FormControl(''),
      bankType : new FormControl(''),
      bankId : new FormControl(''),
      bankAccountId : new FormControl(''),
      payoutCurrency : new FormControl(''),
      pinNo : new FormControl(''),
      //BankCity : new FormControl('', [Validators.required]),
      //bankAddress : new FormControl('', [Validators.required]),
      //bankPostal : new FormControl('', [Validators.required]),
      //accName : new FormControl('', [Validators.required]),
      //bankClass : new FormControl('', [Validators.required]),
      //commissionPerc : new FormControl('', [Validators.required]),
      //passport : new FormControl('', [Validators.required]),
      //dob : new FormControl('', [Validators.required]),
      //driverLicenseNumber : new FormControl('', [Validators.required]),
    })
  }
  
  createMenuLocationsForm() {
    this.menuLocations = this.fb.group({
      alias : new FormControl('', [Validators.required]),
      maxItems : new FormControl('', [Validators.required]),
      //coursePin : new FormControl(''),
      arrangeOption : new FormControl('', [Validators.required]),
      // locationOne : new FormControl(''),
      // locationTwo : new FormControl(''),
      // locationThree : new FormControl(''),
      // locationOneCheck : new FormControl(''),
      // locationTwoCheck : new FormControl(''),
      // locationThreeCheck : new FormControl(''),
      // locationOne_id : new FormControl(''),
      // locationOne_is_deleted : new FormControl(''),
      // locationTwo_is_deleted : new FormControl(''),
      // locationThree_is_deleted : new FormControl(''),
      // locationTwo_id : new FormControl(''),
      // locationThree_id : new FormControl(''),
      defaultRadio: new FormControl(''),
      "courseLocations" : this.fb.array([])
    })
  }
  createMenu(value) {
    // return this.fb.group({
    //   id : new FormControl(value ? value.id : ''),
    //   locationCheck : new FormControl(value ? value.locationCheck : ''),
    //   location : new FormControl(value ? value.location : ''),
    //   location_id : new FormControl(value ? value.location_id : ''),
    //   location_is_deleted : new FormControl(value ? value.location_is_deleted : ''),
    //   locationRadio : new FormControl(value ? value.locationRadio : '')
    // })
    return this.fb.group({
      id : new FormControl(value ? value.id : ''),
      locationCheck : new FormControl((value && value.is_active) ? true : false),
      location : new FormControl(value ? value.name : ''),
      location_id : new FormControl(value ? value.id : ''),
      location_is_deleted : new FormControl(value ? value.is_deleted : ''),
      locationRadio : new FormControl((value && value.is_default) ? 1 : '')
    })
  }
  
  addMenu() {
      this.courseLocations = this.menuLocations.get('courseLocations') as FormArray;
      this.courseLocations.push(this.createMenu(null));
  }

  createWebAppForm() {
    this.webAppForm = this.fb.group({
      paymentStatus : new FormControl('', [Validators.required]),
      golfCourseLogo : new FormControl('', [Validators.required]),
      golfCourseAltText : new FormControl('', [Validators.required]),
      golfCourseTextColor : new FormControl('', [Validators.required]),
      golfCourseBgColor : new FormControl('', [Validators.required]),
      // latitude : new FormControl('', [Validators.required]),
      // longitude : new FormControl('', [Validators.required]),
      // radius : new FormControl('', [Validators.required]),
      // url : new FormControl(''),
    })
  }
  accept_only_numbers(event) {
    var k;
    k=event.charCode;
    return ((k > 47 && k < 58));
  }

  omit_chars(event) {
    var k;
    k=event.charCode;
    if (k > 31 && (k < 48 || k > 57)) {
        return false;
    }
    //return ((k > 47 && k < 58) || k==45);
    return true;
  }

  accept_float_numbers(event) {
    var k;
    k=event.charCode;
    return ((k > 47 && k < 58) || (k == 46));
  }

  accept_alpha_numeric(event) {
    var k;
    k=event.charCode;
    return ((k > 47 && k < 58) || (k > 64 && k < 91) || (k > 96 && k < 123) || (k == 32));
  }

  bank_address_validation(event) {
    var k;
    k=event.charCode;
    return ((k > 43 && k < 58) || (k > 64 && k < 91) || (k > 96 && k < 123) || (k == 32) || (k == 40) || (k == 41));
  }
  
  createTaxesForm() {
    this.taxForm = this.fb.group({
      serviceFee : new FormControl('', [Validators.required]),
      creditFee : new FormControl('', [Validators.required]),
      tipPerc1 : new FormControl('', [Validators.required]),
      deliveryFee : new FormControl(''),
	    enable_no_payment : new FormControl(''),
	    enable_cardconnect : new FormControl(''),
      isBarTender : new FormControl(''),
	    payment_type : new FormControl(''),
	    payment_type_interval : new FormControl(''),
      courseCurrency : new FormControl(''),
      preBookingTime : new FormControl('', this.isTruGolfCourse ? Validators.required : []),
      /* tipPerc2 : new FormControl('', [Validators.required]),
      tipPerc3 : new FormControl('', [Validators.required]),
      tipPerc4 : new FormControl('', [Validators.required]), */
      gstCheck : new FormControl(''),
      pstCheck : new FormControl(''),
      hstCheck : new FormControl(''),
      liquorCheck : new FormControl(''),
      gstValue : new FormControl(''),
      pstValue : new FormControl(''),
      hstValue : new FormControl(''),
      liquorValue : new FormControl(''),
      gstManOpt : new FormControl(''),
      pstManOpt : new FormControl(''),
      hstManOpt : new FormControl(''),
      liquorManOpt : new FormControl(''),
      taxName1 : new FormControl(''),
      taxName2 : new FormControl(''),
      taxName3 : new FormControl(''),
      taxName4 : new FormControl(''),
    })
  }
  
  ngOnInit() {
    this.getClientIP();
    this.userType = localStorage.getItem("usertype");
    this.getTimeZone();
  }

  convertToPhoneNumber(str) {
    let chraArr = [];
    for (var i = 0; i < str.length; i++) {
      if(!isNaN(parseInt(str[i]))) {
        chraArr.push(parseInt(str[i]));
      }
    }
    return ((chraArr.toString()).replace(/,/g, ''));
  }

  getClientIP()  
  {  
    this.dashboardService.getClientIPAddress().then(res => {
      this.clientIP=res.ip;
    });  
  } 

  populateData() {
    if(this.course) {
      let tips = this.course.tipPerc ? this.course.tipPerc.split(',') : [];
     
      /* this.addUserForm.controls['addContact'].disable();
      this.addUserForm.controls['addEmail'].disable();
      this.addUserForm.controls['addCourseName'].disable(); */
      this.addUserForm.controls['addCourseName'].setValue(this.course.name);
      this.addUserForm.controls['firstName'].setValue(this.course.tbl_admin_users[0].name);
      this.addUserForm.controls['addContact'].setValue(this.course.tbl_admin_users[0].phone);
      this.addUserForm.controls['addEmail'].setValue(this.course.tbl_admin_users[0].email);
      
      this.isTruGolfCourse = this.course.isTruGolfCourse;
      
      if(this.course.lightspeedStatus) {
        this.addUserForm.controls['lightspeedStatus'].setValue('1');
        this.lightspeed_Status = '1';
        if(this.course.lightspeedType === null) {
          this.addUserForm.controls['lightspeedType'].setValue('');
        } else {
          this.addUserForm.controls['lightspeedType'].setValue(this.course.lightspeedType);
        }
        this.addUserForm.controls['lightspeedType'].enable();
      } else {
        this.addUserForm.controls['lightspeedStatus'].setValue('0');
        this.lightspeed_Status = '0';
        this.addUserForm.controls['lightspeedType'].setValue('');
        this.addUserForm.controls['lightspeedType'].disable();
      }
      
      
      this.addUserForm.controls['stripeAccountID'].setValue(this.course.stripeAccountID);
      //this.addUserForm.controls['qrCode'].setValue(this.course.qrCode);
      this.CourseQRCode = this.course.qrCode;
      
      
      
      if(this.course.membershipStatus)
        this.addUserForm.controls['membershipStatus'].setValue('1');
      else 
        this.addUserForm.controls['membershipStatus'].setValue('0');
      this.addUserForm.controls['numberofLocations'].setValue((this.course.numberofLocations) ? this.course.numberofLocations : '');

      this.addUserForm.controls['orderSummary'].setValue(this.course.orderSummary);
      this.addUserForm.controls['specific_location'].setValue(this.course.specific_location);
      this.addUserForm.controls['specific_location_choice'].setValue(this.course.specific_location_choice);
      this.addUserForm.controls['editorContent'].setValue(this.course.disclaimerContent);
      //this.menuLocations.controls['coursePin'].setValue(this.course.coursePin);
      this.addUserForm.controls['legalName'].setValue(this.course.legalName);
      this.addUserForm.controls['productAndServiceDesc'].setValue(this.course.productAndServiceDesc);

      this.addUserForm.controls['businessType'].setValue(this.course.businessType);
      this.addUserForm.controls['businessCategory'].setValue(this.course.businessCategory);
      this.addUserForm.controls['taxId'].setValue(this.course.taxId);
      this.addUserForm.controls['salesVolume'].setValue(this.course.salesVolume);
      this.addUserForm.controls['businessRegistrationNumber'].setValue(this.course.businessRegistrationNumber);
      /* this.addUserForm.controls['lastName'].setValue(this.course.tbl_admin_users[0].name.split(' ').slice(-1).join(' ')); */
      //this.courseBankForm.controls['dob'].setValue( new Date(this.course.tbl_course_bluesnap_details.length ? this.course.tbl_course_bluesnap_details[0].dob : '')); 
      //this.courseBankForm.controls['passport'].setValue(this.course.tbl_course_bluesnap_details.length ? this.course.tbl_course_bluesnap_details[0].passportNumber : ''); 
      this.courseBankForm.controls['pinNo'].setValue(this.course.tbl_course_bluesnap_details.length ? this.course.tbl_course_bluesnap_details[0].personalIdentificationNumber : ''); 
      //this.courseBankForm.controls['driverLicenseNumber'].setValue(this.course.tbl_course_bluesnap_details.length ? this.course.tbl_course_bluesnap_details[0].driverLicenseNumber : ''); 
      this.courseAddressForm.controls['province'].setValue(this.course.tbl_admin_users[0].state);
      this.courseAddressForm.controls['country'].setValue(this.course.tbl_admin_users[0].country);
      this.courseAddressForm.controls['city'].setValue(this.course.tbl_admin_users[0].city);
      this.courseAddressForm.controls['courseAddress'].setValue(this.course.tbl_admin_users[0].address ? this.course.tbl_admin_users[0].address.slice(0,40) : '');
      this.courseAddressForm.controls['postal'].setValue(this.course.tbl_admin_users[0].zip);
      let value = (this.course.radius * 69.0585729 * 2).toFixed(0);
      this.courseAddressForm.controls['radius'].setValue(value);
      if(this.course.geometry) {
        this.courseAddressForm.controls['latitude'].setValue(this.course.geometry.coordinates[0]);
        this.courseAddressForm.controls['longitude'].setValue(this.course.geometry.coordinates[1]);
      }
      this.courseAddressForm.controls['url'].setValue(this.course.tbl_admin_users[0].url);
      this.courseAddressForm.controls['course_time_zone'].setValue(this.course.timezone);
      
      // for(let i = 0; i < this.course.tbl_menu_sections.length; i++) {
      //   this.menuLocations.controls['location'+this.formObject[i].length+'_id'].setValue(this.course.tbl_menu_sections[i].id);
      //   this.menuLocations.controls['location'+this.formObject[i].length].setValue(this.course.tbl_menu_sections[i].name);
      //   this.menuLocations.controls['location'+this.formObject[i].length+'_is_deleted'].setValue(this.course.tbl_menu_sections[i].is_deleted); 
      //   this.menuLocations.controls['location'+this.formObject[i].length+'Check'].setValue(true);
      //   if(this.course.tbl_menu_sections[i].is_default) {
      //     this.menuLocations.controls['defaultRadio'].setValue('location'+this.formObject[i].length+'Radio');  
      //   }  
      // }
        
      this.menuLocations.controls['alias'].setValue(this.course.alias);
      this.menuLocations.controls['arrangeOption'].setValue(this.course.arrangeOption);

      this.menuLocations.controls['maxItems'].setValue(this.course.maxItems);
      this.taxForm.controls['gstCheck'].setValue(this.course.is_gst);
      this.taxForm.controls['pstCheck'].setValue(this.course.is_pst);
      this.taxForm.controls['hstCheck'].setValue(this.course.is_hst);
      this.taxForm.controls['liquorCheck'].setValue(this.course.is_liquor);
      this.taxForm.controls['gstValue'].setValue(this.course.gst);
      this.taxForm.controls['pstValue'].setValue(this.course.pst);
      this.taxForm.controls['hstValue'].setValue(this.course.hst);

      this.taxForm.controls['taxName1'].setValue(this.course.taxName1);
      this.taxForm.controls['taxName2'].setValue(this.course.taxName2);
      this.taxForm.controls['taxName3'].setValue(this.course.taxName3);
      this.taxForm.controls['taxName4'].setValue(this.course.taxName4);

      this.taxForm.controls['liquorValue'].setValue(this.course.liquor);
      if(this.course.gst_is_mandatory) 
        this.taxForm.controls['gstManOpt'].setValue('man');
      else 
        this.taxForm.controls['gstManOpt'].setValue('opt');  
      if(this.course.pst_is_mandatory) 
        this.taxForm.controls['pstManOpt'].setValue('man');
      else 
        this.taxForm.controls['pstManOpt'].setValue('opt');
      if(this.course.hst_is_mandatory) 
        this.taxForm.controls['hstManOpt'].setValue('man');
      else 
        this.taxForm.controls['hstManOpt'].setValue('opt');  
      if(this.course.liquor_is_mandatory) 
        this.taxForm.controls['liquorManOpt'].setValue('man');
      else 
        this.taxForm.controls['liquorManOpt'].setValue('opt');

      this.taxForm.controls['serviceFee'].setValue(this.course.serviceFee);
      this.taxForm.controls['tipPerc1'].setValue(tips.length >= 1 ? tips[0] : 18);
      this.taxForm.controls['deliveryFee'].setValue(this.course.deliveryFee);
	    this.taxForm.controls['enable_no_payment'].setValue(this.course.enable_no_payment);

	    this.taxForm.controls['enable_cardconnect'].setValue(this.course.enable_cardconnect);
      this.taxForm.controls['isBarTender'].setValue(this.course.isBarTender);
      this.taxForm.controls['payment_type'].setValue(this.course.payment_type);
	    this.taxForm.controls['payment_type_interval'].setValue(this.course.payment_type_interval);
      this.taxForm.controls['courseCurrency'].setValue(this.course.currency);
      //this.taxForm.controls['tipPerc1'].setValue(tips.length >= 1 ? tips[0] : 5);
      /* this.taxForm.controls['tipPerc2'].setValue(tips.length >= 2 ? tips[1] : 10);
      this.taxForm.controls['tipPerc3'].setValue(tips.length >= 3 ? tips[2] : 15);
      this.taxForm.controls['tipPerc4'].setValue(tips.length >= 4 ? tips[3] : 20); */
      this.taxForm.controls['creditFee'].setValue(this.course.creditcardFee);
      this.taxForm.controls['preBookingTime'].setValue(this.course.preBookingTime);
      
      this.courseBankForm.controls['bankName'].setValue(this.course.tbl_course_bluesnap_details.length ? this.course.tbl_course_bluesnap_details[0].bankName : '');
      //this.courseBankForm.controls['bankAddress'].setValue(this.course.tbl_course_bluesnap_details.length ? this.course.tbl_course_bluesnap_details[0].address : '');
      this.courseBankForm.controls['bankCountry'].setValue(this.course.tbl_course_bluesnap_details.length ? this.course.tbl_course_bluesnap_details[0].country : '');
      this.courseBankForm.controls['bankProvince'].setValue(this.course.tbl_course_bluesnap_details.length ? this.course.tbl_course_bluesnap_details[0].state : '');
      //this.courseBankForm.controls['BankCity'].setValue(this.course.tbl_course_bluesnap_details.length ? this.course.tbl_course_bluesnap_details[0].city : '');
      //this.courseBankForm.controls['bankPostal'].setValue(this.course.tbl_course_bluesnap_details.length ? this.course.tbl_course_bluesnap_details[0].zip : '');
      //this.courseBankForm.controls['accName'].setValue(this.course.tbl_course_bluesnap_details.length ? this.course.tbl_course_bluesnap_details[0].nameOnAccount : '');
      //this.courseBankForm.controls['bankClass'].setValue(this.course.tbl_course_bluesnap_details.length ? this.course.tbl_course_bluesnap_details[0].bankAccountClass : '');
      this.courseBankForm.controls['bankType'].setValue(this.course.tbl_course_bluesnap_details.length ? this.course.tbl_course_bluesnap_details[0].bankAccountType : '');
      this.courseBankForm.controls['payoutCurrency'].setValue(this.course.tbl_course_bluesnap_details.length ? this.course.tbl_course_bluesnap_details[0].baseCurrency : '');
      this.courseBankForm.controls['bankId'].setValue(this.course.tbl_course_bluesnap_details.length ? this.course.tbl_course_bluesnap_details[0].bankId : '');
      this.courseBankForm.controls['bankAccountId'].setValue(this.course.tbl_course_bluesnap_details.length ? this.course.tbl_course_bluesnap_details[0].bankAccountId : '');
      //this.courseBankForm.controls['commissionPerc'].setValue(this.course.tbl_course_bluesnap_details.length ? this.course.tbl_course_bluesnap_details[0].commissionPercent : '');

      this.courseOwnershipForm.controls['isOwnerExists'].setValue(false);

      if(this.course.tbl_course_owners.length) {
        if(this.course.tbl_course_owners.length > 1) {
          this.courseOwnershipForm.controls['isOwnerExists'].setValue(true);
        }
        let temp = this.course.tbl_course_owners;
        for(let i=0; i<temp.length; i++) {
          this.AddRowWithData(temp[i]);
        }
      } else {
        this.AddRowWithData(null);
      }

      /* this.courseOwnershipForm.controls['ownerAddress'].setValue(this.course.tbl_course_owners.length ? this.course.tbl_course_owners[0].ownerAddress : '');
      this.courseOwnershipForm.controls['ownerCity'].setValue(this.course.tbl_course_owners.length ? this.course.tbl_course_owners[0].ownerCity : '');
      this.courseOwnershipForm.controls['ownerCountry'].setValue(this.course.tbl_course_owners.length ? this.course.tbl_course_owners[0].ownerCountry : '');
      this.courseOwnershipForm.controls['ownerDateOfBirth'].setValue(this.course.tbl_course_owners.length ? this.course.tbl_course_owners[0].ownerDateOfBirth : '');
      this.courseOwnershipForm.controls['ownerFirstName'].setValue(this.course.tbl_course_owners.length ? this.course.tbl_course_owners[0].ownerFirstName : '');
      this.courseOwnershipForm.controls['ownerGovID'].setValue(this.course.tbl_course_owners.length ? this.course.tbl_course_owners[0].ownerGovID : '');
      this.courseOwnershipForm.controls['ownerLastName'].setValue(this.course.tbl_course_owners.length ? this.course.tbl_course_owners[0].ownerLastName : '');
      this.courseOwnershipForm.controls['ownerPhone'].setValue(this.course.tbl_course_owners.length ? this.course.tbl_course_owners[0].ownerPhone : '');
      this.courseOwnershipForm.controls['ownerState'].setValue(this.course.tbl_course_owners.length ? this.course.tbl_course_owners[0].ownerState : '');
      this.courseOwnershipForm.controls['ownerZip'].setValue(this.course.tbl_course_owners.length ? this.course.tbl_course_owners[0].ownerZip : ''); */
       // Manage Web App Details
      if(this.course.paymentStatus)
        this.webAppForm.controls['paymentStatus'].setValue('1');
      else 
        this.webAppForm.controls['paymentStatus'].setValue('0');

        this.webAppForm.controls['golfCourseLogo'].setValue(this.course.tbl_webapps.length ? this.course.tbl_webapps[0].golfCourseLogo : '');
        this.webAppForm.controls['golfCourseAltText'].setValue(this.course.tbl_webapps.length ? this.course.tbl_webapps[0].golfCourseAltText : '');
        this.webAppForm.controls['golfCourseTextColor'].setValue(this.course.tbl_webapps.length && this.course.tbl_webapps[0].golfCourseTextColor ? this.course.tbl_webapps[0].golfCourseTextColor : '#ef486e');
        this.webAppForm.controls['golfCourseBgColor'].setValue(this.course.tbl_webapps.length && this.course.tbl_webapps[0].golfCourseBgColor? this.course.tbl_webapps[0].golfCourseBgColor : '#e45a33');
        this.selectedTextColor = (this.course.tbl_webapps.length && this.course.tbl_webapps[0].golfCourseTextColor) ? this.course.tbl_webapps[0].golfCourseTextColor : '#ef486e' ;
        this.selectedBgColor = (this.course.tbl_webapps.length && this.course.tbl_webapps[0].golfCourseBgColor) ? this.course.tbl_webapps[0].golfCourseBgColor : '#e45a33';
        this.uploadedLogo = this.course.tbl_webapps.length ? this.uploadPath+this.course.tbl_webapps[0].golfCourseLogo : '';

        //new add menu
        if(this.course.tbl_menu_sections.length) {
          let temp = this.course.tbl_menu_sections;
          for(let i=0; i<temp.length; i++) {
            this.AddRowWithDataMenu(temp[i]);
          }
        } else {
          this.AddRowWithDataMenu(null);
        }

      let totalLocations = ( 3 + parseInt(this.course.numberofLocations) ) -  this.course.tbl_menu_sections.length ;
      // Create remaining rows
      for( let i = 0; i < totalLocations; i++) {
        this.AddRowWithDataMenu(null);
      }
      // Create remaining rows
      //let finallLocations = ( 3 + parseInt(this.course.numberofLocations) ) ;
  
      if(this.course.tbl_menu_sections.length) {
        let temp = this.course.tbl_menu_sections;
        for(let i=0; i<temp.length; i++) {
          if(this.course.tbl_menu_sections[i].is_default) {
            this.menuLocations.controls['defaultRadio'].setValue('location_'+i);
          } 
        }
      }
    }
    else {
    this.addUserForm.controls['addCourseName'].setValue('');
    this.addUserForm.controls['firstName'].setValue('');
    this.addUserForm.controls['legalName'].setValue('');
    this.addUserForm.controls['productAndServiceDesc'].setValue('');

    this.addUserForm.controls['businessType'].setValue('');
    this.addUserForm.controls['businessCategory'].setValue('');
    this.addUserForm.controls['taxId'].setValue('');
    this.addUserForm.controls['salesVolume'].setValue('');
    this.addUserForm.controls['businessRegistrationNumber'].setValue('');
    this.addUserForm.controls['lightspeedStatus'].setValue('0');
    this.addUserForm.controls['lightspeedType'].setValue('');
    this.lightspeed_Status = '0';
    this.addUserForm.controls['lightspeedType'].disable();

    this.addUserForm.controls['stripeAccountID'].setValue('');
    //this.addUserForm.controls['qrCode'].setValue('');
    this.CourseQRCode = '';
    this.addUserForm.controls['membershipStatus'].setValue('0');
    this.addUserForm.controls['numberofLocations'].setValue('');
    this.addUserForm.controls['orderSummary'].setValue('');
    this.addUserForm.controls['specific_location'].setValue('');
    this.addUserForm.controls['specific_location_choice'].setValue('');
    this.addUserForm.controls['editorContent'].setValue('');

    this.courseAddressForm.controls['province'].setValue('');
    this.courseAddressForm.controls['country'].setValue('');
    this.courseAddressForm.controls['city'].setValue('');
    this.courseAddressForm.controls['courseAddress'].setValue('');
    this.courseAddressForm.controls['course_time_zone'].setValue('');
    this.courseAddressForm.controls['radius'].setValue('7');
    
    this.courseOwnershipForm.controls['isOwnerExists'].setValue(false);
    this.AddRowWithData(null);

    /* this.courseOwnershipForm.controls['ownerCity'].setValue('');
    this.courseOwnershipForm.controls['ownerCountry'].setValue('');
    this.courseOwnershipForm.controls['ownerDateOfBirth'].setValue('');
    this.courseOwnershipForm.controls['ownerFirstName'].setValue('');
    this.courseOwnershipForm.controls['ownerGovID'].setValue('');
    this.courseOwnershipForm.controls['ownerLastName'].setValue('');
    this.courseOwnershipForm.controls['ownerPhone'].setValue('');
    this.courseOwnershipForm.controls['ownerState'].setValue('');
    this.courseOwnershipForm.controls['ownerZip'].setValue(''); */

    this.addUserForm.controls['addContact'].setValue('');
    this.addUserForm.controls['addEmail'].setValue('');
    this.menuLocations.controls['defaultRadio'].setValue('location_0');
    this.menuLocations.controls['alias'].setValue('Popular Items');
    this.menuLocations.controls['arrangeOption'].setValue('Top of Category');
    this.menuLocations.controls['maxItems'].setValue(6);
    this.taxForm.controls['gstCheck'].setValue(true);
    this.taxForm.controls['pstCheck'].setValue(false);
    this.taxForm.controls['hstCheck'].setValue(false);
    this.taxForm.controls['liquorCheck'].setValue(false);
    this.taxForm.controls['gstManOpt'].setValue('man');
    this.taxForm.controls['pstManOpt'].setValue('opt');
    this.taxForm.controls['hstManOpt'].setValue('opt');
    this.taxForm.controls['liquorManOpt'].setValue('opt');

    this.taxForm.controls['gstValue'].setValue('');
    this.taxForm.controls['pstValue'].setValue('');
    this.taxForm.controls['hstValue'].setValue('');
    this.taxForm.controls['liquorValue'].setValue('');

    this.taxForm.controls['taxName1'].setValue('');
    this.taxForm.controls['taxName2'].setValue('');
    this.taxForm.controls['taxName3'].setValue('');
    this.taxForm.controls['taxName4'].setValue('');

    this.taxForm.controls['serviceFee'].setValue(10);
    this.taxForm.controls['creditFee'].setValue(2.9);
    this.taxForm.controls['preBookingTime'].setValue('');
    this.taxForm.controls['tipPerc1'].setValue(18);
    this.taxForm.controls['deliveryFee'].setValue(0);
    this.taxForm.controls['enable_no_payment'].setValue('');
    this.taxForm.controls['enable_cardconnect'].setValue(0);
    this.taxForm.controls['isBarTender'].setValue('');
    this.taxForm.controls['payment_type'].setValue('now');
    this.taxForm.controls['payment_type_interval'].setValue('');
    this.taxForm.controls['courseCurrency'].setValue('');
   // this.taxForm.controls['tipPerc1'].setValue(5);
    /* this.taxForm.controls['tipPerc2'].setValue(10);
    this.taxForm.controls['tipPerc3'].setValue(15);
    this.taxForm.controls['tipPerc4'].setValue(20); */
    //this.courseBankForm.controls['commissionPerc'].setValue(7);
    // Manage Web App Details
    this.selectedTextColor = '#ef486e';
    this.selectedBgColor = '#e45a33';
    this.webAppForm.controls['paymentStatus'].setValue('0');
    this.webAppForm.controls['golfCourseLogo'].setValue('Logo.png');
    this.uploadedLogo = this.uploadPath+'Logo.png';
    this.webAppForm.controls['golfCourseAltText'].setValue('');
    this.webAppForm.controls['golfCourseTextColor'].setValue(this.selectedTextColor);
    this.webAppForm.controls['golfCourseBgColor'].setValue(this.selectedBgColor);
    let totalLocations = 3;

    this.isTruGolfCourse = 0;

    // Create rows
    for( let i = 0; i < totalLocations; i++) {
      this.AddRowWithDataMenu(null);
    }
    this.menuLocations.controls['defaultRadio'].setValue('location_0');
    }
    this.onTabPaymentSelect();
 }
  get addCourseName() {return this.addUserForm.get('addCourseName'); }
  
  get firstName() {return this.addUserForm.get('firstName'); }

  get dob() {return this.courseBankForm.get('dob'); }

  get passport() {return this.courseBankForm.get('passport'); }

  get pinNo() {return this.courseBankForm.get('pinNo'); }

  get driverLicenseNumber() {return this.courseBankForm.get('driverLicenseNumber'); }
  
  get lastName() {return this.addUserForm.get('lastName'); }
  
  get addContact() {return this.addUserForm.get('addContact'); }
  
  get addEmail() {return this.addUserForm.get('addEmail'); }

  get addPassword() {return this.addUserForm.get('addPassword'); }

  get addConfirmPassword() {return this.addUserForm.get('addConfirmPassword'); }
  
  get province() {return this.courseAddressForm.get('province'); }

  get course_time_zone() {return this.courseAddressForm.get('course_time_zone'); }

  get country() {return this.courseAddressForm.get('country'); }
  
  get city() {return this.courseAddressForm.get('city'); }
  
  get courseAddress() {return this.courseAddressForm.get('courseAddress'); }
  
  get postal() {return this.courseAddressForm.get('postal'); }

  get latitude() {return this.courseAddressForm.get('latitude'); }

  get radius() {return this.courseAddressForm.get('radius'); }

  get longitude() {return this.courseAddressForm.get('longitude'); }
  
  get url() {return this.courseAddressForm.get('url'); }
  
  get alias() {return this.menuLocations.get('alias'); }

  get arrangeOption() {return this.menuLocations.get('arrangeOption'); }
  
  get locationOne() {return this.menuLocations.get('locationOne'); }
  
  get locationTwo() {return this.menuLocations.get('locationTwo'); }
  
  get locationThree() {return this.menuLocations.get('locationThree'); }
  
  get maxItems() {return this.menuLocations.get('maxItems'); }

  get coursePin() {return this.menuLocations.get('coursePin'); }
  
  get serviceFee() {return this.taxForm.get('serviceFee'); }
  
  get creditFee() {return this.taxForm.get('creditFee'); }

  get preBookingTime() {return this.taxForm.get('preBookingTime'); }
  
  
  get tipPerc1() {return this.taxForm.get('tipPerc1'); }
  get deliveryFee() {return this.taxForm.get('deliveryFee'); }
  get enable_no_payment() {return this.taxForm.get('enable_no_payment'); }
  get enable_cardconnect() {return this.taxForm.get('enable_cardconnect'); }
  get isBarTender() {return this.taxForm.get('isBarTender'); }
  get payment_type() {return this.taxForm.get('payment_type'); }
  get payment_type_interval() {return this.taxForm.get('payment_type_interval'); }
  get courseCurrency() {return this.taxForm.get('courseCurrency'); }

  /* get tipPerc2() {return this.taxForm.get('tipPerc2'); }

  get tipPerc3() {return this.taxForm.get('tipPerc3'); }

  get tipPerc4() {return this.taxForm.get('tipPerc4'); } */
  
  get gstCheck() {return this.taxForm.get('gstCheck'); }
  
  get pstCheck() {return this.taxForm.get('pstCheck'); }
  
  get hstCheck() {return this.taxForm.get('hstCheck'); }
  
  get liquorCheck() {return this.taxForm.get('liquorCheck'); }
  
  get gstValue() {return this.taxForm.get('gstValue'); }
  
  get pstValue() {return this.taxForm.get('gstValue'); }
  
  get hstValue() {return this.taxForm.get('gstValue'); }
  
  get liquorValue() {return this.taxForm.get('liquorValue'); }

  get taxName1() {return this.taxForm.get('taxName1'); }

  get taxName2() {return this.taxForm.get('taxName2'); }

  get taxName3() {return this.taxForm.get('taxName3'); }

  get taxName4() {return this.taxForm.get('taxName4'); }
  
  get gstManOpt() {return this.taxForm.get('gstManOpt'); }
  
  get pstManOpt() {return this.taxForm.get('pstManOpt'); }
  
  get hstManOpt() {return this.taxForm.get('hstManOpt'); }
  
  get liquorManOpt() {return this.taxForm.get('liquorManOpt'); }
  
  get locationOne_id() {return this.addUserForm.get('locationOne_id'); }

  get locationOne_is_deleted() {return this.addUserForm.get('locationOne_is_deleted'); }

  get locationTwo_is_deleted() {return this.addUserForm.get('locationOne_is_deleted'); }

  get locationThree_is_deleted() {return this.addUserForm.get('locationOne_is_deleted'); }

  get locationTwo_id() {return this.addUserForm.get('locationTwo_id'); }

  get locationThree_id() {return this.addUserForm.get('locationThree_id'); }

  get bankName() {return this.courseBankForm.get('bankName'); }

  get bankAddress() {return this.courseBankForm.get('bankAddress'); }

  get bankProvince() {return this.courseBankForm.get('bankProvince'); }

  get bankCountry() {return this.courseBankForm.get('bankCountry'); }

  get BankCity() {return this.courseBankForm.get('BankCity'); }

  get bankPostal() {return this.courseBankForm.get('bankPostal'); }

  get accName() {return this.courseBankForm.get('accName'); }

  get bankClass() {return this.courseBankForm.get('bankClass'); }

  get bankType() {return this.courseBankForm.get('bankType'); }

  get payoutCurrency() {return this.courseBankForm.get('payoutCurrency'); }

  get bankId() {return this.courseBankForm.get('bankId'); }

  get bankAccountId() {return this.courseBankForm.get('bankAccountId'); }

  //get commissionPerc() {return this.courseBankForm.get('commissionPerc'); }

  get legalName() {return this.addUserForm.get('legalName'); }

  get productAndServiceDesc() {return this.addUserForm.get('productAndServiceDesc'); }

  get businessType() {return this.addUserForm.get('businessType'); }

  get businessCategory() {return this.addUserForm.get('businessCategory'); }

  get taxId() {return this.addUserForm.get('taxId'); }

  get salesVolume() {return this.addUserForm.get('salesVolume'); }

  get businessRegistrationNumber() {return this.addUserForm.get('businessRegistrationNumber'); }

  get ownerFirstName() {return this.courseOwnershipForm.get('ownerFirstName'); }

  get ownerLastName() {return this.courseOwnershipForm.get('ownerLastName'); }

  get ownerPhone() {return this.courseOwnershipForm.get('ownerPhone'); }

  get ownerDateOfBirth() {return this.courseOwnershipForm.get('ownerDateOfBirth'); }

  get ownerGovID() {return this.courseOwnershipForm.get('ownerGovID'); }

  get ownerCountry() {return this.courseOwnershipForm.get('ownerCountry'); }

  get ownerState() {return this.courseOwnershipForm.get('ownerState'); }

  get ownerCity() {return this.courseOwnershipForm.get('ownerCity'); }

  get ownerAddress() {return this.courseOwnershipForm.get('ownerAddress'); }

  get ownerZip() {return this.courseOwnershipForm.get('ownerZip'); }

  get courseOwnershipForms() { return this.courseOwnershipForm.get('owners') as FormArray; };

  get lightspeedStatus() {return this.addUserForm.get('lightspeedStatus'); }
  get lightspeedType() {return this.addUserForm.get('lightspeedType'); }
  get stripeAccountID() {return this.addUserForm.get('stripeAccountID'); }
  //get qrCode() {return this.addUserForm.get('qrCode'); }
  get membershipStatus() {return this.addUserForm.get('membershipStatus'); }
  get numberofLocations() {return this.addUserForm.get('numberofLocations'); }
  get orderSummary() {return this.addUserForm.get('orderSummary'); }
  get specific_location() {return this.addUserForm.get('specific_location'); }
  get specific_location_choice() {return this.addUserForm.get('specific_location_choice'); }
  get editorContent() {return this.addUserForm.get('editorContent'); }
  get paymentStatus() {return this.webAppForm.get('paymentStatus'); }
  get golfCourseLogo() {return this.webAppForm.get('golfCourseLogo'); }
  get golfCourseAltText() {return this.webAppForm.get('golfCourseAltText'); }
  get golfCourseTextColor() {return this.webAppForm.get('golfCourseTextColor'); }
  get golfCourseBgColor() {return this.webAppForm.get('golfCourseBgColor'); }

  get locationCheck() {return this.menuLocations.get('locationCheck'); }
  get location() {return this.menuLocations.get('location'); }
  get location_id() {return this.menuLocations.get('location_id'); }
  get location_is_deleted() {return this.menuLocations.get('location_is_deleted'); }
  get locationRadio() {return this.menuLocations.get('locationRadio'); }
  
  get courseLocationsForm() { return this.menuLocations.get('courseLocations') as FormArray; };

  
  close(): void {
    this.dialogRef.close();
  }

  getCountriesDropdown(filters) {
    this.dashboardService.getCountriesDropdown(filters).then(data => {
      if(data.success) {
        this.countries = data.results;
      }
      else {
        this.countries = [];
        this.alertService.createAlert(data.message,0);
      }
    })
  }

  getCitiesDropdown(filters, type, from) {
    if(!filters)
      return;
    let obj = {"stateId":filters};
    this.dashboardService.getCitiesDropdown(obj).then(data => {
      if(data.success) {
        if(type ==1)
          this.cities = data.results;
        else if(type ==2)
          this.bankCities = data.results;
        else if(type ==4)
          this.repCities = data.results;
        else if(type ==5 || type ==6 || type ==7 || type ==8)
          this.ownersCities[''+type] = data.results;

        if(type == 1  && !from)
          this.populateData();
      }
      else {
        this.cities = [];
        this.alertService.createAlert(data.message,0);
      }
    })
  }

  getProvincesDropdown(filters, type) {
    if(!filters)
      return;
    let obj = {"countryId":filters};
    this.dashboardService.getProvinces(obj).then(data => {
      if(data.success) {
        if(type ==1)
          this.provinces = data.results;
        else if(type ==2)
          this.repProvinces = data.results;
        else if(type ==3)
          this.bankProvinces = data.results;
        else if(type ==4 || type ==5 || type ==6 || type ==7)
          this.ownersProvinces[''+type] = data.results;
      }
      else {
        this.provinces = [];
        this.alertService.createAlert(data.message,0);
      }
    })
  }

  zipcodeChange(zipcode) {
    this.dashboardService.verifyAddress(zipcode).subscribe(responseList => {
        if(responseList['status'] == 'OK') {
          //let temp = responseList.results[0]['geometry'];
          //let location = temp.location;
          /* let north = temp.bounds.northeast;
          let south = temp.bounds.southwest; */
          //this.zipCords = [[location.lat,location.lng],[north.lat, north.lng],[south.lat,south.lng],[north.lat, north.lng],[location.lat,location.lng]];
          //this.zipCords = location.lat + ' '+ location.lng;
          //console.log(this.zipCords);
          this.isValidZipcode = 0;
        } else {
          this.isValidZipcode = 1;
        } 
    });
  }

  sortFloat(a,b) { return a - b; }
  
  // saveCourse_old() {

  //   console.log(this.courseAddressForm.value);
  //   console.log(this.addUserForm.value);
  //   console.log(this.courseBankForm.value);
  //   console.log(this.courseOwnershipForm.value);
  //   let finalObj = {};
  //   let adminUser = {};
  //   let course = {};
  //   let vendor = {};
  //   let  merchantBlueSnapObj = {};
  //   merchantBlueSnapObj['businessInfo'] = {};
  //   let curr_date = this.datepipe.transform( new Date(Date.now() - 86400000), 'MM/dd/yyyy');
  //   let aggrement = {
  //     "serviceAgreementDate": curr_date,
  //     "pricingAgreementDate":curr_date,
  //     //"merchantIp": this.clientIP
  //   };
  //   merchantBlueSnapObj['merchantAgreementsSign'] = JSON.parse(JSON.stringify(aggrement));
  //   merchantBlueSnapObj['merchantAgreementsSign']["merchantIp"] = this.clientIP;
  //   course = aggrement;
  //   let menu = [];
  //   let taxes = [];
  //   let tax = {};
    
  //   //if(this.course && this.course.is_signup_completed) {
  //     /* course['name'] = this.course.name;
  //     menu['courseName'] = this.course.name;
  //     vendorBlueSnapObj['name'] = this.course.name;
  //     vendorBlueSnapObj['phone'] = this.course.tbl_admin_users[0].phone;
  //     course['courseEmail'] = this.course.tbl_admin_users[0].email;
  //     vendorBlueSnapObj['email'] = this.course.tbl_admin_users[0].email;
  //     vendorBlueSnapObj['vendorPrincipal']['email'] = this.course.tbl_admin_users[0].email;
  //     adminUser['phone'] = this.course.tbl_admin_users[0].phone;
  //     adminUser['email'] = this.course.tbl_admin_users[0].email; */
  //     if(this.course) {
  //       vendor['username'] = this.course.tbl_course_bluesnap_details.length ? this.course.tbl_course_bluesnap_details[0].username : '';
  //       if(this.course.tbl_admin_users[0].phone != this.addUserForm.value.addContact)
  //         adminUser['phoneChanged'] = 1;
  //       if(this.course.tbl_admin_users[0].email != this.addUserForm.value.addEmail)
  //         adminUser['emailChanged'] = 1;
  //     }
  //   //}
  //   //else {
  //     course['name'] = this.addUserForm.value.addCourseName;
  //     course['legalName'] = this.addUserForm.value.legalName;
  //     course['productAndServiceDesc'] = this.addUserForm.value.productAndServiceDesc;
  //     course['businessType'] = this.addUserForm.value.businessType;
  //     course['businessCategory'] = this.addUserForm.value.businessCategory;
  //     course['taxId'] = this.addUserForm.value.taxId;
  //     course['salesVolume'] = this.addUserForm.value.salesVolume;
  //     course['businessRegistrationNumber'] = this.addUserForm.value.businessRegistrationNumber;

  //     menu['courseName'] = this.addUserForm.value.addCourseName;
  //     course['courseEmail'] = this.addUserForm.value.addEmail;
  //     if(this.addUserForm.value.lightspeedStatus == 1) {
  //       course['lightspeedStatus'] = true;
  //     }
  //     else {
  //       course['lightspeedStatus'] = false;
  //     }
  //     course['stripeAccountID'] = this.addUserForm.value.stripeAccountID;
  //     if(this.addUserForm.value.membershipStatus == 1) {
  //       course['membershipStatus'] = true;
  //     }
  //     else {
  //       course['membershipStatus'] = false;
  //     }

  //     merchantBlueSnapObj['businessInfo']['businessName'] = this.addUserForm.value.addCourseName;
  //     merchantBlueSnapObj['businessInfo']['legalName'] = this.addUserForm.value.legalName;
  //     merchantBlueSnapObj['businessInfo']['productAndServiceDesc'] = this.addUserForm.value.productAndServiceDesc;
  //     merchantBlueSnapObj['businessInfo']['email'] = "cody@9-eighteen.com";//this.addUserForm.value.addEmail;
  //     merchantBlueSnapObj['businessInfo']['phone'] = this.addUserForm.value.addContact;
  //     merchantBlueSnapObj['businessInfo']['website'] = this.courseAddressForm.value.url;
  //     merchantBlueSnapObj['businessInfo']['businessType'] = this.addUserForm.value.businessType;
  //     merchantBlueSnapObj['businessInfo']['businessCategory'] = this.addUserForm.value.businessCategory;
  //     merchantBlueSnapObj['businessInfo']['taxId'] = this.addUserForm.value.taxId;
  //     //vendorBlueSnapObj['vendorPrincipal']['email'] = this.addUserForm.value.addEmail;
  //     adminUser['phone'] = this.addUserForm.value.addContact;
  //     adminUser['email'] = this.addUserForm.value.addEmail;
  //     //adminUser['password'] = this.addUserForm.value.addPassword;
  //   //}
  //   /* vendorBlueSnapObj['country'] = this.countries.find(o => o.id == this.courseAddressForm.value.country).sortname;
  //   vendorBlueSnapObj['address'] = this.courseAddressForm.value.courseAddress;
  //   vendorBlueSnapObj['city'] = this.courseAddressForm.value.city;
  //   vendorBlueSnapObj['zip'] = this.courseAddressForm.value.postal;
  //   vendorBlueSnapObj['frequency'] = 'WEEKLY';   //'DAILY';
  //   vendorBlueSnapObj['state'] = this.provinces.find(o => o.id == this.courseAddressForm.value.province).shortCode;
  //   vendorBlueSnapObj["defaultPayoutCurrency"] = this.courseBankForm.value.payoutCurrency; */
  //   adminUser['name'] = this.addUserForm.value.firstName;
  //   //vendorBlueSnapObj['vendorPrincipal']['firstName'] = this.addUserForm.value.firstName;
  //   //vendorBlueSnapObj['vendorPrincipal']['lastName'] = ' ';
  //   merchantBlueSnapObj['businessInfo']['address'] = this.courseAddressForm.value.courseAddress.slice(0,40);
  //   merchantBlueSnapObj['businessInfo']['city'] = this.cities.find(o => o.id == this.courseAddressForm.value.city).name;
  //   merchantBlueSnapObj['businessInfo']['state'] = this.provinces.find(o => o.id == this.courseAddressForm.value.province).shortCode;
  //   merchantBlueSnapObj['businessInfo']['zip'] = this.courseAddressForm.value.postal;
  //   //vendorBlueSnapObj['vendorPrincipal']['dob'] = this.datepipe.transform(this.courseBankForm.value.dob, 'dd-MM-yyyy');
  //   //vendorBlueSnapObj['vendorPrincipal']['personalIdentificationNumber'] = this.courseBankForm.value.pinNo;
  //   //vendorBlueSnapObj['vendorPrincipal']['driverLicenseNumber'] = this.courseBankForm.value.driverLicenseNumber;
  //   //vendorBlueSnapObj['vendorPrincipal']['passportNumber'] = this.courseBankForm.value.passport;
  //   merchantBlueSnapObj['businessInfo']['country'] = this.countries.find(o => o.id == this.courseAddressForm.value.country).sortname;
  //   merchantBlueSnapObj['businessInfo']['salesVolume'] = this.addUserForm.value.salesVolume;

  //   if(merchantBlueSnapObj['businessInfo']['country'] == 'CA') {
  //     merchantBlueSnapObj['businessInfo']['businessRegistrationNumber'] = this.addUserForm.value.businessRegistrationNumber;
  //   }

  //   adminUser['address'] = this.courseAddressForm.value.courseAddress.slice(0,40)
  //   adminUser['city'] = this.courseAddressForm.value.city;
  //   adminUser['state'] = this.courseAddressForm.value.province;
  //   adminUser['country'] = this.courseAddressForm.value.country;
  //   adminUser['zip'] = this.courseAddressForm.value.postal;
  //   course['radius'] = ((this.courseAddressForm.value.radius / 2 )/69.0585729);
  //   //if(this.zipCords.length) {
  //   course['is_signup_completed'] = 1;
  //   course['geometry'] = this.courseAddressForm.value.latitude + ' '+  this.courseAddressForm.value.longitude;
  //   //}
  //   adminUser['url'] = this.courseAddressForm.value.url;
  //   course['serviceFee'] = this.taxForm.value.serviceFee;
  //   //course['tipPerc'] = [this.taxForm.value.tipPerc1, this.taxForm.value.tipPerc2, this.taxForm.value.tipPerc3, this.taxForm.value.tipPerc4].sort(this.sortFloat).join(',');
  //   course['tipPerc'] = this.taxForm.value.tipPerc1;
  //   course['creditcardFee'] = this.taxForm.value.creditFee;
  //   course['alias'] = this.menuLocations.value.alias;
  //   course['currency'] = this.courseBankForm.value.payoutCurrency;
  //   course['maxItems'] = this.menuLocations.value.maxItems;
  //   course['coursePin'] = this.menuLocations.value.coursePin;
  //   merchantBlueSnapObj['bankingInfo'] = {};
  //   merchantBlueSnapObj['companyRep'] = {};
  //   //vendorBlueSnapObj['vendorAgreement']['commissionPercent'] = (100 - parseFloat(this.courseBankForm.value.commissionPerc));
  //   vendor['bankName'] = this.courseBankForm.value.bankName;
  //   //vendor['address'] = this.courseBankForm.value.bankAddress;
  //   vendor['state_id'] = this.courseBankForm.value.bankProvince;
  //   //vendor['city'] = this.bankCities.find(o => o.id == this.courseBankForm.value.BankCity).name;
  //   //vendor['city_id'] = this.courseBankForm.value.BankCity;
  //   //vendor['zip'] = this.courseBankForm.value.bankPostal;
  //   //vendor['nameOnAccount'] = this.courseBankForm.value.accName;
  //   //vendor['bankAccountClass'] = this.courseBankForm.value.bankClass;
  //   vendor['bankAccountType'] = this.courseBankForm.value.bankType;
  //   vendor['bankId'] = this.courseBankForm.value.bankId;
  //   vendor['bankAccountId'] = this.courseBankForm.value.bankAccountId;
  //   //vendor['commissionPercent'] = this.courseBankForm.value.commissionPerc;
  //   vendor['payoutType'] = "ACH";
  //   vendor['baseCurrency'] = this.courseBankForm.value.payoutCurrency;
  //   vendor['country_id'] = this.courseBankForm.value.bankCountry;
  //   if(this.courseBankForm.value.bankCountry !='')
  //     vendor['country'] = (this.courseBankForm.value.bankCountry !='') ? this.countries.find(o => o.id == this.courseBankForm.value.bankCountry).sortname : '';
  //   else 
  //     vendor['country'] = '';
    
  //   if(merchantBlueSnapObj['businessInfo']['country'] == 'US' || merchantBlueSnapObj['businessInfo']['country'] == 'CA') {
  //     vendor['state'] = (this.courseBankForm.value.bankProvince != '') ? this.bankProvinces.find(o => o.id == this.courseBankForm.value.bankProvince).shortCode : '';
  //   } else { 
  //     this.alertService.createAlert("Currently supporting only US and Canada courses",0);
  //   }

  //   merchantBlueSnapObj['bankingInfo']['accountNumber'] = this.courseBankForm.value.bankAccountId;
  //   merchantBlueSnapObj['bankingInfo']["payoutCurrency"] = this.courseBankForm.value.payoutCurrency;
    
  //   if( (merchantBlueSnapObj['businessInfo']['country'] == 'CA') && (vendor['country'] == 'US') && (merchantBlueSnapObj['bankingInfo']["payoutCurrency"] == 'USD') ) {
  //     merchantBlueSnapObj['bankingInfo']['routingNumber'] = this.courseBankForm.value.bankId;
  //     merchantBlueSnapObj['bankingInfo']['bankState'] = vendor['state'];
  //     merchantBlueSnapObj['bankingInfo']['accountType'] = this.courseBankForm.value.bankType;
  //     merchantBlueSnapObj['bankingInfo']['bankCountry'] = vendor['country'];
  //   } 
  //   else if( (merchantBlueSnapObj['businessInfo']['country'] == 'CA') && (vendor['country'] == 'CA') && (merchantBlueSnapObj['bankingInfo']["payoutCurrency"] == 'CAD') ) {
  //     merchantBlueSnapObj['bankingInfo']['bankProvince'] = vendor['state'];
  //     merchantBlueSnapObj['bankingInfo']['transitNumber'] = this.courseBankForm.value.bankId;
  //     merchantBlueSnapObj['bankingInfo']['institutionNumber'] = this.courseBankForm.value.pinNo;
  //   }
  //   else if( (merchantBlueSnapObj['businessInfo']['country'] == 'CA') && (vendor['country'] == 'CA') && (merchantBlueSnapObj['bankingInfo']["payoutCurrency"] == 'USD') ) {
  //     merchantBlueSnapObj['bankingInfo']['bankCountry'] = vendor['country'];
  //     merchantBlueSnapObj['bankingInfo']['bankName'] = this.courseBankForm.value.bankName;
  //     merchantBlueSnapObj['bankingInfo']['bankProvince'] = vendor['state'];
  //     merchantBlueSnapObj['bankingInfo']['transitNumber'] = this.courseBankForm.value.bankId;
  //     merchantBlueSnapObj['bankingInfo']['institutionNumber'] = this.courseBankForm.value.pinNo;
  //   }
  //   else if( (merchantBlueSnapObj['businessInfo']['country'] == 'US') && (vendor['country'] == 'US') && (merchantBlueSnapObj['bankingInfo']["payoutCurrency"] == 'USD') ) {
  //     merchantBlueSnapObj['bankingInfo']['routingNumber'] = this.courseBankForm.value.bankId;
  //     merchantBlueSnapObj['bankingInfo']['accountType'] = this.courseBankForm.value.bankType;
  //     merchantBlueSnapObj['bankingInfo']['bankState'] = vendor['state'];
  //     merchantBlueSnapObj['bankingInfo']['bankCountry'] = vendor['country'];
  //   }
  //   else {
  //     this.alertService.createAlert("Bank country or Payout currency needs to be changed",0);
  //   }
    
  //   merchantBlueSnapObj['bankingInfo']['minimalPayoutAmount'] = 35;
  //   merchantBlueSnapObj['bankingInfo']['refundReserve'] = 100;

  //   vendor['minimalPayoutAmount'] = 35;
  //   vendor['refundReserve'] = 100;

  //   let ownersList = this.courseOwnershipForm.value.owners;
  //   let ownersFinalArray = [];
  //   let bluesnapOwnersArray = [];
  //   for(let i=0; i< ownersList.length; i++) {
  //       let ownerObj = {};
  //       ownerObj['id'] = ownersList[i].id;
  //       ownerObj['ownerFirstName'] = ownersList[i].ownerFirstName;
  //       ownerObj['ownerLastName'] = ownersList[i].ownerLastName;
  //       ownerObj['ownerDateOfBirth'] = this.datepipe.transform( ownersList[i].ownerDateOfBirth, 'MM/dd/yyyy');
  //       ownerObj['ownerGovID'] = ownersList[i].ownerGovID;
  //       ownerObj['ownerPhone'] = ownersList[i].ownerPhone;
  //       ownerObj['ownerAddress'] = ownersList[i].ownerAddress.slice(0,40);
  //       ownerObj['ownerZip'] = ownersList[i].ownerZip;
  //       ownerObj['ownerCountry'] = ownersList[i].ownerCountry;
  //       ownerObj['ownerState'] = ownersList[i].ownerState;
  //       ownerObj['ownerCity'] = ownersList[i].ownerCity;
  //       ownerObj['isOwner'] = ownersList[i].isOwner;
  //       ownersFinalArray.push(JSON.parse(JSON.stringify(ownerObj)));
  //       if(ownersList[i].isOwner) {
  //         ownerObj['zip'] = ownerObj['ownerZip'];
  //         ownerObj['address'] = ownerObj['ownerAddress'];
  //         ownerObj['city'] = (ownersList[i].ownerCity!='') ? this.ownersCities[i+4].find(o => o.id == ownersList[i].ownerCity).name : '';
  //         ownerObj['country'] = (ownersList[i].ownerCountry) ? this.countries.find(o => o.id == ownersList[i].ownerCountry).sortname : '';
  //         if(ownerObj['country'] == "US" || ownerObj['country'] == "CA") {
  //           ownerObj['state'] = this.ownersProvinces[i+3].find(o => o.id == ownersList[i].ownerState).shortCode;
  //         }
  //         delete ownerObj['isOwner'];
  //         delete ownerObj['ownerAddress'];
  //         delete ownerObj['ownerZip'];
  //         delete ownerObj['ownerCountry'];
  //         delete ownerObj['ownerState'];
  //         delete ownerObj['ownerCity'];
  //         bluesnapOwnersArray.push(JSON.parse(JSON.stringify(ownerObj)));
  //       } else {
  //         merchantBlueSnapObj['companyRep']['companyRepFirstName'] = ownersList[i].ownerFirstName;
  //         merchantBlueSnapObj['companyRep']['companyRepLastName'] = ownersList[i].ownerLastName;
  //         merchantBlueSnapObj['companyRep']['companyRepDateOfBirth'] = this.datepipe.transform( ownersList[i].ownerDateOfBirth, 'MM/dd/yyyy');
  //         merchantBlueSnapObj['companyRep']['companyRepGovID'] = ownersList[i].ownerGovID;
  //         merchantBlueSnapObj['companyRep']['companyRepPhone'] = ownersList[i].ownerPhone;
  //         merchantBlueSnapObj['companyRep']['companyRepAddress'] = ownersList[i].ownerAddress.slice(0,40);
  //         merchantBlueSnapObj['companyRep']['companyRepCity'] = (ownersList[i].ownerCity!='') ? this.repCities.find(o => o.id == ownersList[i].ownerCity).name : '';
  //         merchantBlueSnapObj['companyRep']['companyRepZip'] = ownersList[i].ownerZip;
  //         merchantBlueSnapObj['companyRep']['companyRepCountry'] = (ownersList[i].ownerCountry!='') ? this.countries.find(o => o.id == ownersList[i].ownerCountry).sortname : '';
  //         if(merchantBlueSnapObj['companyRep']['companyRepCountry'] == "US" || merchantBlueSnapObj['companyRep']['companyRepCountry'] == "CA") {
  //           merchantBlueSnapObj['companyRep']['companyRepState'] = this.repProvinces.find(o => o.id == ownersList[i].ownerState).shortCode;
  //         }
  //       }
  //   }

  //   if(bluesnapOwnersArray.length) {
  //     merchantBlueSnapObj['ownershipInfoArr'] = bluesnapOwnersArray;
  //   }

  //   //let sd = new Date(this.courseBankForm.value.dob);
  //   //vendor["dob"] = sd.setTime(sd.getTime() + (330 * 60 * 1000));
  //   vendor['personalIdentificationNumber'] = this.courseBankForm.value.pinNo;
  //   //vendor['driverLicenseNumber'] = this.courseBankForm.value.driverLicenseNumber;
  //   //vendor['passportNumber'] = this.courseBankForm.value.passport;
  //   if(this.menuLocations.value.locationOneCheck) {
  //     let obj = {};
  //     obj['menu'] = this.menuLocations.value.locationOne;
  //     if(this.course) {
  //       obj['id'] = this.menuLocations.value.locationOne_id;
  //       obj['is_deleted'] = this.menuLocations.value.locationOne_is_deleted;
  //     }
  //     if(this.menuLocations.value.defaultRadio == 'locationOneRadio') {
  //       obj['is_default'] = true;
  //     }
  //     else {
  //       obj['is_default'] = false;
  //     }
  //     obj['is_active'] = true;
  //     menu.push(obj);
  //   }
    
  //   if(this.menuLocations.value.locationTwoCheck) {
  //     let obj = {};
  //     obj['menu'] = this.menuLocations.value.locationTwo;
  //     if(this.course) {
  //       obj['id'] = this.menuLocations.value.locationTwo_id;
  //       obj['is_deleted'] = this.menuLocations.value.locationTwo_is_deleted;
  //     }
  //     if(this.menuLocations.value.defaultRadio == 'locationTwoRadio') {
  //       obj['is_default'] = true;
  //     }
  //     else {
  //       obj['is_default'] = false;
  //     }
  //     obj['is_active'] = true;
  //     menu.push(obj);
  //   }
    
  //   if(this.menuLocations.value.locationThreeCheck) {
  //     let obj = {};
  //     obj['menu'] = this.menuLocations.value.locationThree;
  //     if(this.course) {
  //       obj['id'] = this.menuLocations.value.locationThree_id;
  //       obj['is_deleted'] = this.menuLocations.value.locationThree_is_deleted;
  //     }
  //     if(this.menuLocations.value.defaultRadio == 'locationThreeRadio') {
  //       obj['is_default'] = true;
  //     }
  //     else {
  //       obj['is_default'] = false;
  //     }
  //     obj['is_active'] = true;
  //     menu.push(obj);
  //   }
    
  //   if(this.taxForm.value.gstCheck) {
  //     let obj = {};
  //     tax['is_gst'] = true;
  //     tax['gst'] = this.taxForm.value.gstValue;
  //     if(this.taxForm.value.gstManOpt == "man") {
  //       tax['gst_is_mandatory'] = true;
  //     }
  //     else {
  //       tax['gst_is_mandatory'] = false;
  //     }
  //     //taxes.push(obj);
  //   }
    
  //   if(!this.taxForm.value.gstCheck) {
  //     let obj = {};
  //     tax['is_gst'] = false;
  //     tax['gst'] = 0;
  //     tax['gst_is_mandatory'] = false;
  //     //taxes.push(obj);
  //   }
    
  //   if(this.taxForm.value.pstCheck) {
  //     let obj = {};
  //     tax['is_pst'] = true;
  //     tax['pst'] = this.taxForm.value.pstValue;
  //     if(this.taxForm.value.pstManOpt == "man") {
  //       tax['pst_is_mandatory'] = true;
  //     }
  //     else {
  //       tax['pst_is_mandatory'] = false;
  //     }
  //     //taxes.push(obj);
  //   }
    
  //   if(!this.taxForm.value.pstCheck) {
  //     let obj = {};
  //     tax['is_pst'] = false;
  //     tax['pst'] = 0;
  //     tax['pst_is_mandatory'] = false;
  //     //taxes.push(obj);
  //   }
    
  //   if(this.taxForm.value.hstCheck) {
  //     let obj = {};
  //     tax['is_hst'] = true;
  //     tax['hst'] = this.taxForm.value.hstValue;
  //     if(this.taxForm.value.hstManOpt == "man") {
  //       tax['hst_is_mandatory'] = true;
  //     }
  //     else {
  //       tax['hst_is_mandatory'] = false;
  //     }
  //     //taxes.push(obj);
  //   }
    
  //   if(!this.taxForm.value.hstCheck) {
  //     let obj = {};
  //     tax['is_hst'] = false;
  //     tax['hst'] = 0;
  //     tax['hst_is_mandatory'] = false;
  //     //taxes.push(obj);
  //   }
    
  //   if(this.taxForm.value.liquorCheck) {
  //     let obj = {};
  //     tax['is_liquor'] = true;
  //     tax['liquor'] = this.taxForm.value.liquorValue;
  //     if(this.taxForm.value.liquorManOpt == "man") {
  //       tax['liquor_is_mandatory'] = true;
  //     }
  //     else {
  //       tax['liquor_is_mandatory'] = false;
  //     }
  //     //taxes.push(obj);
  //   }
    
  //   if(!this.taxForm.value.liquorCheck) {
  //     let obj = {};
  //     tax['is_liquor'] = false;
  //     tax['liquor'] = 0;
  //     tax['liquor_is_mandatory'] = false;
  //   }
  //   tax['taxName1'] = this.taxForm.value.taxName1;
  //   tax['taxName2'] = this.taxForm.value.taxName2;
  //   tax['taxName3'] = this.taxForm.value.taxName3;
  //   tax['taxName4'] = this.taxForm.value.taxName4;
  //   finalObj['adminUser'] = adminUser;
  //   finalObj['course'] = course;
  //   finalObj['vendor'] = vendor;
  //   finalObj['owners'] = ownersFinalArray;
  //   finalObj['vendorBlueSnapObj'] = merchantBlueSnapObj;
  //   finalObj['menuSections'] = menu;
  //   finalObj['taxes'] = tax;
  //   if(this.course) {
  //     finalObj['id'] = this.course.id;
  //     //finalObj['course'].is_active = true;
  //     finalObj['course'].is_deleted = false;
  //     console.log(finalObj);
  //     console.log('Final Object of Course in update>>', finalObj);
  //     this.dashboardService.updateCourse(finalObj).then(data => {
  //       if(data.success) {
  //         this.alertService.createAlert("Course updated successfully.",1);
  //         this.dialogRef.close('save');
  //       }
  //       else {
  //         this.alertService.createAlert(data.message,0);
  //       }
  //     })
  //   }
  //   else {
  //     //finalObj['course'].is_active = true;
  //     finalObj['course'].is_deleted = false;
  //     console.log('Final Object of Course add>>', finalObj);
  //     this.dashboardService.addCourse(finalObj).then(data => {
  //       if(data.success) {
  //         this.alertService.createAlert(data.message,1);
  //         this.dialogRef.close('save');
  //       }
  //       else {
  //         this.alertService.createAlert(data.message,0);
  //       }
  //     });
  //   }
  // }
//Add Course
saveCourse() {
  console.log('addUserForm: ',this.addUserForm.value);
  console.log('courseAddressForm: ',this.courseAddressForm.value);
  console.log('courseOwnershipForm: ',this.courseOwnershipForm.value);
  console.log('menuLocations: ',this.menuLocations.value);
  console.log('taxForm: ',this.taxForm.value);
  console.log('webAppForm: ',this.webAppForm.value);
  //console.log('courseBankForm: ',this.courseBankForm.value);

  let adminUser = {};
  let course = {};
  let finalObj = {};
  let webapp = {};
// za  let vendor = {};
  /* za
  let merchantBlueSnapObj = {};
      merchantBlueSnapObj['businessInfo'] = {};
  */
  let curr_date = this.datepipe.transform( new Date(Date.now() - 86400000), 'MM/dd/yyyy');
  let aggrement = {
    "serviceAgreementDate": curr_date,
    "pricingAgreementDate":curr_date,
    //"merchantIp": this.clientIP
  };
  /* za
  merchantBlueSnapObj['merchantAgreementsSign'] = JSON.parse(JSON.stringify(aggrement));
  merchantBlueSnapObj['merchantAgreementsSign']["merchantIp"] = this.clientIP;
  */
  course = aggrement;
  let menu = [];
  let taxes = [];
  let tax = {};
  
  //if(this.course && this.course.is_signup_completed) {
    /* course['name'] = this.course.name;
    menu['courseName'] = this.course.name;
    vendorBlueSnapObj['name'] = this.course.name;
    vendorBlueSnapObj['phone'] = this.course.tbl_admin_users[0].phone;
    course['courseEmail'] = this.course.tbl_admin_users[0].email;
    vendorBlueSnapObj['email'] = this.course.tbl_admin_users[0].email;
    vendorBlueSnapObj['vendorPrincipal']['email'] = this.course.tbl_admin_users[0].email;
    adminUser['phone'] = this.course.tbl_admin_users[0].phone;
    adminUser['email'] = this.course.tbl_admin_users[0].email; */
    
    if(this.course) {
      // za        vendor['username'] = this.course.tbl_course_bluesnap_details.length ? this.course.tbl_course_bluesnap_details[0].username : '';
      if(this.course.tbl_admin_users[0].phone != this.addUserForm.value.addContact)
        adminUser['phoneChanged'] = 1;
      if(this.course.tbl_admin_users[0].email != this.addUserForm.value.addEmail)
        adminUser['emailChanged'] = 1;

        //Reset the GMT into local storage
          // Get the existing data
          var AllLocalStorage = localStorage;
          if(AllLocalStorage['usertype'] == 'user'){
              var existing = localStorage.getItem('login_user_info');
              // If no existing data, create an array
              // Otherwise, convert the localStorage string to an array
              existing = existing ? JSON.parse(existing) : {};
              // Add new data to localStorage Array
              existing['courseData']['course_time_zone'] = this.courseAddressForm.value.course_time_zone;
              
              if(this.taxForm.value.gstCheck) {
                let obj = {};
                existing['courseData']['is_gst'] = true;
                existing['courseData']['gst'] = this.taxForm.value.gstValue;
                
                if(this.taxForm.value.gstManOpt == "man") {
                  existing['courseData']['gst_is_mandatory'] = true;
                }
                else {
                  existing['courseData']['gst_is_mandatory'] = false;
                }
              }
              
              if(!this.taxForm.value.gstCheck) {
                let obj = {};
                existing['courseData']['is_gst'] = false;
                existing['courseData']['gst'] = 0;
                existing['courseData']['gst_is_mandatory'] = false;
              }
              
              if(this.taxForm.value.pstCheck) {
                let obj = {};
                existing['courseData']['is_pst'] = true;
                existing['courseData']['pst'] = this.taxForm.value.pstValue;
                if(this.taxForm.value.pstManOpt == "man") {
                  existing['courseData']['pst_is_mandatory'] = true;
                }
                else {
                  existing['courseData']['pst_is_mandatory'] = false;
                }
              }
              
              if(!this.taxForm.value.pstCheck) {
                let obj = {};
                existing['courseData']['is_pst'] = false;
                existing['courseData']['pst'] = 0;
                existing['courseData']['pst_is_mandatory'] = false;
              }
              
              if(this.taxForm.value.hstCheck) {
                let obj = {};
                existing['courseData']['is_hst'] = true;
                existing['courseData']['hst'] = this.taxForm.value.hstValue;
                if(this.taxForm.value.hstManOpt == "man") {
                  existing['courseData']['hst_is_mandatory'] = true;
                }
                else {
                  existing['courseData']['hst_is_mandatory'] = false;
                }
              }
              
              if(!this.taxForm.value.hstCheck) {
                let obj = {};
                existing['courseData']['is_hst'] = false;
                existing['courseData']['hst'] = 0;
                existing['courseData']['hst_is_mandatory'] = false;
              }
              
              if(this.taxForm.value.liquorCheck) {
                let obj = {};
                existing['courseData']['is_liquor'] = true;
                existing['courseData']['liquor'] = this.taxForm.value.liquorValue;
                if(this.taxForm.value.liquorManOpt == "man") {
                  existing['courseData']['liquor_is_mandatory'] = true;
                }
                else {
                  existing['courseData']['liquor_is_mandatory'] = false;
                }
              }
              
              if(!this.taxForm.value.liquorCheck) {
                let obj = {};
                existing['courseData']['is_liquor'] = false;
                existing['courseData']['liquor'] = 0;
                existing['courseData']['liquor_is_mandatory'] = false;
              }
            
              existing['courseData']['taxName1'] = this.taxForm.value.taxName1;
              existing['courseData']['taxName2'] = this.taxForm.value.taxName2;
              existing['courseData']['taxName3'] = this.taxForm.value.taxName3;
              existing['courseData']['taxName4'] = this.taxForm.value.taxName4;

              // Save back to localStorage
              localStorage.setItem('login_user_info', JSON.stringify(existing));
          }
        //
    }
  //}
  //else {

    // Create data for Course Table
    course['name'] = this.addUserForm.value.addCourseName;
    course['legalName'] = this.addUserForm.value.legalName;
    course['productAndServiceDesc'] = this.addUserForm.value.productAndServiceDesc;
    course['businessType'] = this.addUserForm.value.businessType;
    course['businessCategory'] = this.addUserForm.value.businessCategory;
    course['taxId'] = this.addUserForm.value.taxId;
    course['salesVolume'] = this.addUserForm.value.salesVolume;
    course['businessRegistrationNumber'] = this.addUserForm.value.businessRegistrationNumber;
    course['courseEmail'] = this.addUserForm.value.addEmail;
    if(this.addUserForm.value.lightspeedStatus == 1) {
      course['lightspeedStatus'] = true;
      course['lightspeedType'] = this.addUserForm.value.lightspeedType;
      this.addUserForm.controls['lightspeedType'].enable();
      this.lightspeed_Status = '1';
    }
    else {
      course['lightspeedStatus'] = false;
      course['lightspeedType'] = '';
      this.addUserForm.controls['lightspeedType'].disable();
      this.lightspeed_Status = '0';
    }
    
    course['stripeAccountID'] = this.addUserForm.value.stripeAccountID;
    //course['qrCode'] = this.addUserForm.value.qrCode;
    if(this.addUserForm.value.membershipStatus == 1) {
      course['membershipStatus'] = true;
    }
    else {
      course['membershipStatus'] = false;
    }

    //exit;
    
    course['numberofLocations'] = this.addUserForm.value.numberofLocations;
    course['orderSummary'] = this.addUserForm.value.orderSummary;
    course['specific_location'] = this.addUserForm.value.specific_location;
    course['specific_location_choice'] = this.addUserForm.value.specific_location_choice;
    course['disclaimerContent'] = this.addUserForm.value.editorContent;
    course['disclaimerStatus'] = 0;
    if(this.addUserForm.value.editorContent != '') {
      course['disclaimerStatus'] = 1;
    }
    course['radius'] = ((this.courseAddressForm.value.radius / 2 )/69.0585729);
    course['is_signup_completed'] = 1;
    course['geometry'] = this.courseAddressForm.value.latitude + ' '+  this.courseAddressForm.value.longitude;
    course['course_time_zone'] = this.courseAddressForm.value.course_time_zone;
    course['serviceFee'] = this.taxForm.value.serviceFee;
    course['tipPerc'] = this.taxForm.value.tipPerc1;
    course['deliveryFee'] = this.taxForm.value.deliveryFee;
	  course['enable_no_payment'] = this.taxForm.value.enable_no_payment;
	  course['enable_cardconnect'] = this.taxForm.value.enable_cardconnect;
    course['isBarTender'] = this.taxForm.value.isBarTender;
    course['payment_type'] = this.taxForm.value.payment_type;
	  course['payment_type_interval'] = this.taxForm.value.payment_type_interval;
    course['currency'] = this.taxForm.value.courseCurrency;
    course['creditcardFee'] = this.taxForm.value.creditFee;
    course['preBookingTime'] = this.taxForm.value.preBookingTime;
    course['alias'] = this.menuLocations.value.alias;
    course['arrangeOption'] = this.menuLocations.value.arrangeOption;
    //course['currency'] = 'CAD'; //this.courseBankForm.value.payoutCurrency;
    course['maxItems'] = this.menuLocations.value.maxItems;
    course['coursePin'] = this.menuLocations.value.coursePin;

    if(this.webAppForm.value.paymentStatus == 1) {
      course['paymentStatus'] = true;
    }
    else {
      course['paymentStatus'] = false;
    }
    
    // Create data for menu Table
    menu['courseName'] = this.addUserForm.value.addCourseName;

    // Create data for adminUser Table
    adminUser['phone'] = this.convertToPhoneNumber(this.addUserForm.value.addContact);
    adminUser['email'] = this.addUserForm.value.addEmail;
    adminUser['name'] = this.addUserForm.value.firstName;
    adminUser['address'] = this.courseAddressForm.value.courseAddress.slice(0,40)
    adminUser['city'] = this.courseAddressForm.value.city;
    adminUser['state'] = this.courseAddressForm.value.province;
    adminUser['country'] = this.courseAddressForm.value.country;
    adminUser['zip'] = this.courseAddressForm.value.postal;
    adminUser['url'] = this.courseAddressForm.value.url;

     // Create data for webapps Table
     webapp['golfCourseLogo'] = this.webAppForm.value.golfCourseLogo;
     webapp['golfCourseAltText'] = this.webAppForm.value.golfCourseAltText;
     webapp['golfCourseTextColor'] = this.webAppForm.value.golfCourseTextColor;
     webapp['golfCourseBgColor'] = this.webAppForm.value.golfCourseBgColor;
    /* za
      merchantBlueSnapObj['businessInfo']['businessName'] = this.addUserForm.value.addCourseName;
      merchantBlueSnapObj['businessInfo']['legalName'] = this.addUserForm.value.legalName;
      merchantBlueSnapObj['businessInfo']['productAndServiceDesc'] = this.addUserForm.value.productAndServiceDesc;
      merchantBlueSnapObj['businessInfo']['email'] = "cody@9-eighteen.com"; //this.addUserForm.value.addEmail;
      merchantBlueSnapObj['businessInfo']['phone'] = this.addUserForm.value.addContact;
      merchantBlueSnapObj['businessInfo']['website'] = this.courseAddressForm.value.url;
      merchantBlueSnapObj['businessInfo']['businessType'] = this.addUserForm.value.businessType;
      merchantBlueSnapObj['businessInfo']['businessCategory'] = this.addUserForm.value.businessCategory;
      merchantBlueSnapObj['businessInfo']['taxId'] = this.addUserForm.value.taxId;
    */
    
    //vendorBlueSnapObj['vendorPrincipal']['email'] = this.addUserForm.value.addEmail;
    
    //adminUser['password'] = this.addUserForm.value.addPassword;
  //}
  /* vendorBlueSnapObj['country'] = this.countries.find(o => o.id == this.courseAddressForm.value.country).sortname;
  vendorBlueSnapObj['address'] = this.courseAddressForm.value.courseAddress;
  vendorBlueSnapObj['city'] = this.courseAddressForm.value.city;
  vendorBlueSnapObj['zip'] = this.courseAddressForm.value.postal;
  vendorBlueSnapObj['frequency'] = 'WEEKLY';   //'DAILY';
  vendorBlueSnapObj['state'] = this.provinces.find(o => o.id == this.courseAddressForm.value.province).shortCode;
  vendorBlueSnapObj["defaultPayoutCurrency"] = this.courseBankForm.value.payoutCurrency; */
  
  //vendorBlueSnapObj['vendorPrincipal']['firstName'] = this.addUserForm.value.firstName;
  //vendorBlueSnapObj['vendorPrincipal']['lastName'] = ' ';
  
  
 
  //vendorBlueSnapObj['vendorPrincipal']['dob'] = this.datepipe.transform(this.courseBankForm.value.dob, 'dd-MM-yyyy');
  //vendorBlueSnapObj['vendorPrincipal']['personalIdentificationNumber'] = this.courseBankForm.value.pinNo;
  //vendorBlueSnapObj['vendorPrincipal']['driverLicenseNumber'] = this.courseBankForm.value.driverLicenseNumber;
  //vendorBlueSnapObj['vendorPrincipal']['passportNumber'] = this.courseBankForm.value.passport;
   /* za
    
    merchantBlueSnapObj['businessInfo']['address'] = this.courseAddressForm.value.courseAddress.slice(0,40);
    merchantBlueSnapObj['businessInfo']['city'] = this.cities.find(o => o.id == this.courseAddressForm.value.city).name;
    merchantBlueSnapObj['businessInfo']['state'] = this.provinces.find(o => o.id == this.courseAddressForm.value.province).shortCode;
    merchantBlueSnapObj['businessInfo']['zip'] = this.courseAddressForm.value.postal;
    merchantBlueSnapObj['businessInfo']['country'] = this.countries.find(o => o.id == this.courseAddressForm.value.country).sortname;
    merchantBlueSnapObj['businessInfo']['salesVolume'] = this.addUserForm.value.salesVolume;
    if(merchantBlueSnapObj['businessInfo']['country'] == 'CA') {
      merchantBlueSnapObj['businessInfo']['businessRegistrationNumber'] = this.addUserForm.value.businessRegistrationNumber;
    }
  */

  

  //if(this.zipCords.length) {
  
  //}
  
  
  //course['tipPerc'] = [this.taxForm.value.tipPerc1, this.taxForm.value.tipPerc2, this.taxForm.value.tipPerc3, this.taxForm.value.tipPerc4].sort(this.sortFloat).join(',');
/*
  merchantBlueSnapObj['bankingInfo'] = {};
  merchantBlueSnapObj['companyRep'] = {};
*/
  //vendorBlueSnapObj['vendorAgreement']['commissionPercent'] = (100 - parseFloat(this.courseBankForm.value.commissionPerc));
  //vendor['address'] = this.courseBankForm.value.bankAddress;
  //vendor['city'] = this.bankCities.find(o => o.id == this.courseBankForm.value.BankCity).name;
  //vendor['city_id'] = this.courseBankForm.value.BankCity;
  //vendor['zip'] = this.courseBankForm.value.bankPostal;
  //vendor['nameOnAccount'] = this.courseBankForm.value.accName;
  //vendor['bankAccountClass'] = this.courseBankForm.value.bankClass;
  //vendor['commissionPercent'] = this.courseBankForm.value.commissionPerc;

/* za
  vendor['bankName'] = this.courseBankForm.value.bankName;
  vendor['state_id'] = this.courseBankForm.value.bankProvince;
  vendor['bankAccountType'] = this.courseBankForm.value.bankType;
  vendor['bankId'] = this.courseBankForm.value.bankId;
  vendor['bankAccountId'] = this.courseBankForm.value.bankAccountId;
  vendor['payoutType'] = "ACH";
  vendor['baseCurrency'] = this.courseBankForm.value.payoutCurrency;
  vendor['country_id'] = this.courseBankForm.value.bankCountry;
*/
/*
  if(this.courseBankForm.value.bankCountry !='')
    vendor['country'] = (this.courseBankForm.value.bankCountry !='') ? this.countries.find(o => o.id == this.courseBankForm.value.bankCountry).sortname : '';
  else 
    vendor['country'] = '';
  vendor['minimalPayoutAmount'] = 35;
  vendor['refundReserve'] = 100;
  vendor['personalIdentificationNumber'] = this.courseBankForm.value.pinNo;
*/

  let businessInfoCountry = this.countries.find(o => o.id == this.courseAddressForm.value.country).sortname;
  
  console.log('Suppored Country: ', businessInfoCountry);

  if(businessInfoCountry != 'US' && businessInfoCountry != 'CA') {
    this.alertService.createAlert("Currently supporting only US and Canada courses",0);
    return false;
    //   vendor['state'] = (this.courseBankForm.value.bankProvince != '') ? this.bankProvinces.find(o => o.id == this.courseBankForm.value.bankProvince).shortCode : '';
    // } else {
  }
  else{
    console.log('not Suppored Country: ', businessInfoCountry);
  }
  /* za
    merchantBlueSnapObj['bankingInfo']['accountNumber'] = this.courseBankForm.value.bankAccountId;
    merchantBlueSnapObj['bankingInfo']["payoutCurrency"] = this.courseBankForm.value.payoutCurrency;
    
    if( (merchantBlueSnapObj['businessInfo']['country'] == 'CA') && (vendor['country'] == 'US') && (merchantBlueSnapObj['bankingInfo']["payoutCurrency"] == 'USD') ) {
      merchantBlueSnapObj['bankingInfo']['routingNumber'] = this.courseBankForm.value.bankId;
      merchantBlueSnapObj['bankingInfo']['bankState'] = vendor['state'];
      merchantBlueSnapObj['bankingInfo']['accountType'] = this.courseBankForm.value.bankType;
      merchantBlueSnapObj['bankingInfo']['bankCountry'] = vendor['country'];
    } 
    else if( (merchantBlueSnapObj['businessInfo']['country'] == 'CA') && (vendor['country'] == 'CA') && (merchantBlueSnapObj['bankingInfo']["payoutCurrency"] == 'CAD') ) {
      merchantBlueSnapObj['bankingInfo']['bankProvince'] = vendor['state'];
      merchantBlueSnapObj['bankingInfo']['transitNumber'] = this.courseBankForm.value.bankId;
      merchantBlueSnapObj['bankingInfo']['institutionNumber'] = this.courseBankForm.value.pinNo;
    }
    else if( (merchantBlueSnapObj['businessInfo']['country'] == 'CA') && (vendor['country'] == 'CA') && (merchantBlueSnapObj['bankingInfo']["payoutCurrency"] == 'USD') ) {
      merchantBlueSnapObj['bankingInfo']['bankCountry'] = vendor['country'];
      merchantBlueSnapObj['bankingInfo']['bankName'] = this.courseBankForm.value.bankName;
      merchantBlueSnapObj['bankingInfo']['bankProvince'] = vendor['state'];
      merchantBlueSnapObj['bankingInfo']['transitNumber'] = this.courseBankForm.value.bankId;
      merchantBlueSnapObj['bankingInfo']['institutionNumber'] = this.courseBankForm.value.pinNo;
    }
    else if( (merchantBlueSnapObj['businessInfo']['country'] == 'US') && (vendor['country'] == 'US') && (merchantBlueSnapObj['bankingInfo']["payoutCurrency"] == 'USD') ) {
      merchantBlueSnapObj['bankingInfo']['routingNumber'] = this.courseBankForm.value.bankId;
      merchantBlueSnapObj['bankingInfo']['accountType'] = this.courseBankForm.value.bankType;
      merchantBlueSnapObj['bankingInfo']['bankState'] = vendor['state'];
      merchantBlueSnapObj['bankingInfo']['bankCountry'] = vendor['country'];
    }
    else {
      this.alertService.createAlert("Bank country or Payout currency needs to be changed",0);
    }
    
    merchantBlueSnapObj['bankingInfo']['minimalPayoutAmount'] = 35;
    merchantBlueSnapObj['bankingInfo']['refundReserve'] = 100;
  */

  // Create data for course owners Table
  let ownersList = this.courseOwnershipForm.value.owners;
  let ownersFinalArray = [];
// za  let bluesnapOwnersArray = [];
//   for(let i=0; i< ownersList.length; i++) {
//       let ownerObj = {};
//       ownerObj['id'] = ownersList[i].id;
//       ownerObj['ownerFirstName'] = ownersList[i].ownerFirstName;
//       ownerObj['ownerLastName'] = ownersList[i].ownerLastName;
//       ownerObj['ownerPhone'] = ownersList[i].ownerPhone;
//       ownerObj['ownerDateOfBirth'] = this.datepipe.transform( ownersList[i].ownerDateOfBirth, 'MM/dd/yyyy');
//       ownerObj['ownerGovID'] = ownersList[i].ownerGovID;
//       ownerObj['ownerCountry'] = ownersList[i].ownerCountry;
//       ownerObj['ownerState'] = ownersList[i].ownerState;
//       ownerObj['ownerCity'] = ownersList[i].ownerCity;
//       ownerObj['ownerAddress'] = ownersList[i].ownerAddress.slice(0,40);
//       ownerObj['ownerZip'] = ownersList[i].ownerZip;
//       ownerObj['isOwner'] = ownersList[i].isOwner;
//       ownersFinalArray.push(JSON.parse(JSON.stringify(ownerObj)));
//       if(ownersList[i].isOwner) {
//         ownerObj['zip'] = ownerObj['ownerZip'];
//         ownerObj['address'] = ownerObj['ownerAddress'];
//         ownerObj['city'] = (ownersList[i].ownerCity!='') ? this.ownersCities[i+4].find(o => o.id == ownersList[i].ownerCity).name : '';
//         ownerObj['country'] = (ownersList[i].ownerCountry) ? this.countries.find(o => o.id == ownersList[i].ownerCountry).sortname : '';
//         if(ownerObj['country'] == "US" || ownerObj['country'] == "CA") {
//           ownerObj['state'] = (ownersList[i].ownerState!='') ? this.ownersProvinces[i+3].find(o => o.id == ownersList[i].ownerState).shortCode : '';
//         }
//         delete ownerObj['isOwner'];
//         delete ownerObj['ownerAddress'];
//         delete ownerObj['ownerZip'];
//         delete ownerObj['ownerCountry'];
//         delete ownerObj['ownerState'];
//         delete ownerObj['ownerCity'];
// // za        bluesnapOwnersArray.push(JSON.parse(JSON.stringify(ownerObj)));
//       } else {
//             /* za
//               merchantBlueSnapObj['companyRep']['companyRepFirstName'] = ownersList[i].ownerFirstName;
//               merchantBlueSnapObj['companyRep']['companyRepLastName'] = ownersList[i].ownerLastName;
//               merchantBlueSnapObj['companyRep']['companyRepDateOfBirth'] = this.datepipe.transform( ownersList[i].ownerDateOfBirth, 'MM/dd/yyyy');
//               merchantBlueSnapObj['companyRep']['companyRepGovID'] = ownersList[i].ownerGovID;
//               merchantBlueSnapObj['companyRep']['companyRepPhone'] = ownersList[i].ownerPhone;
//               merchantBlueSnapObj['companyRep']['companyRepAddress'] = ownersList[i].ownerAddress.slice(0,40);
//               merchantBlueSnapObj['companyRep']['companyRepCity'] = (ownersList[i].ownerCity!='') ? this.repCities.find(o => o.id == ownersList[i].ownerCity).name : '';
//               merchantBlueSnapObj['companyRep']['companyRepZip'] = ownersList[i].ownerZip;
//               merchantBlueSnapObj['companyRep']['companyRepCountry'] = (ownersList[i].ownerCountry!='') ? this.countries.find(o => o.id == ownersList[i].ownerCountry).sortname : '';
//               if(merchantBlueSnapObj['companyRep']['companyRepCountry'] == "US" || merchantBlueSnapObj['companyRep']['companyRepCountry'] == "CA") {
//                 merchantBlueSnapObj['companyRep']['companyRepState'] = this.repProvinces.find(o => o.id == ownersList[i].ownerState).shortCode;
//               }
//           */
//       }
//   }
  /* za
    if(bluesnapOwnersArray.length) {
      merchantBlueSnapObj['ownershipInfoArr'] = bluesnapOwnersArray;
    }
  */
  //let sd = new Date(this.courseBankForm.value.dob);
  //vendor["dob"] = sd.setTime(sd.getTime() + (330 * 60 * 1000));
  
  //vendor['driverLicenseNumber'] = this.courseBankForm.value.driverLicenseNumber;
  //vendor['passportNumber'] = this.courseBankForm.value.passport;
// console.log('this.menuLocations: ', this.menuLocations);
// console.log('this.menuLocations: ', this.menuLocations.value.courseLocations);


let TotalActiveMenues = 0;
  for(let i = 0; i < this.menuLocations.value.courseLocations.length; i++) {
 //   if(this.menuLocations.value.courseLocations[i]) {
      let obj = {};
      let compareRadio = 'location_'+i;
      obj['menu'] = this.menuLocations.value.courseLocations[i].location;
      if(this.course) {
        obj['id'] = this.menuLocations.value.courseLocations[i].location_id;
        obj['is_deleted'] = this.menuLocations.value.courseLocations[i].location_is_deleted;
      }
    //  console.log('this.menuLocations.value.defaultRadio: ', this.menuLocations.value.defaultRadio);
    //  console.log('compareRadio: ', compareRadio);
      if(this.menuLocations.value.defaultRadio == compareRadio) {
        obj['is_default'] = true;
      }
      else {
        obj['is_default'] = false;
      }
      if(this.menuLocations.value.courseLocations[i].locationCheck){
        obj['is_active'] = true;
        TotalActiveMenues++;
      }
      else
        obj['is_active'] = false;
    //  console.log('TotalActiveMenues: ', TotalActiveMenues);
     // obj['is_active'] = this.menuLocations.value.courseLocations[i].locationCheck ? true : false;
      menu.push(obj);
   // }
  }
  // console.log('TotalActiveMenues: ', TotalActiveMenues);
  // console.log('this.menu: ', menu);

  //return false;
  // if(this.menuLocations.value.locationOneCheck) {
  //   let obj = {};
  //   obj['menu'] = this.menuLocations.value.locationOne;
  //   if(this.course) {
  //     obj['id'] = this.menuLocations.value.locationOne_id;
  //     obj['is_deleted'] = this.menuLocations.value.locationOne_is_deleted;
  //   }
  //   if(this.menuLocations.value.defaultRadio == 'locationOneRadio') {
  //     obj['is_default'] = true;
  //   }
  //   else {
  //     obj['is_default'] = false;
  //   }
  //   obj['is_active'] = true;
  //   menu.push(obj);
  // }
  
  // if(this.menuLocations.value.locationTwoCheck) {
  //   let obj = {};
  //   obj['menu'] = this.menuLocations.value.locationTwo;
  //   if(this.course) {
  //     obj['id'] = this.menuLocations.value.locationTwo_id;
  //     obj['is_deleted'] = this.menuLocations.value.locationTwo_is_deleted;
  //   }
  //   if(this.menuLocations.value.defaultRadio == 'locationTwoRadio') {
  //     obj['is_default'] = true;
  //   }
  //   else {
  //     obj['is_default'] = false;
  //   }
  //   obj['is_active'] = true;
  //   menu.push(obj);
  // }
  
  // if(this.menuLocations.value.locationThreeCheck) {
  //   let obj = {};
  //   obj['menu'] = this.menuLocations.value.locationThree;
  //   if(this.course) {
  //     obj['id'] = this.menuLocations.value.locationThree_id;
  //     obj['is_deleted'] = this.menuLocations.value.locationThree_is_deleted;
  //   }
  //   if(this.menuLocations.value.defaultRadio == 'locationThreeRadio') {
  //     obj['is_default'] = true;
  //   }
  //   else {
  //     obj['is_default'] = false;
  //   }
  //   obj['is_active'] = true;
  //   menu.push(obj);
  // }
  
  if(this.taxForm.value.gstCheck) {
    let obj = {};
    tax['is_gst'] = true;
    tax['gst'] = this.taxForm.value.gstValue;
    if(this.taxForm.value.gstManOpt == "man") {
      tax['gst_is_mandatory'] = true;
    }
    else {
      tax['gst_is_mandatory'] = false;
    }
    //taxes.push(obj);
  }
  
  if(!this.taxForm.value.gstCheck) {
    let obj = {};
    tax['is_gst'] = false;
    tax['gst'] = 0;
    tax['gst_is_mandatory'] = false;
    //taxes.push(obj);
  }
  
  if(this.taxForm.value.pstCheck) {
    let obj = {};
    tax['is_pst'] = true;
    tax['pst'] = this.taxForm.value.pstValue;
    if(this.taxForm.value.pstManOpt == "man") {
      tax['pst_is_mandatory'] = true;
    }
    else {
      tax['pst_is_mandatory'] = false;
    }
    //taxes.push(obj);
  }
  
  if(!this.taxForm.value.pstCheck) {
    let obj = {};
    tax['is_pst'] = false;
    tax['pst'] = 0;
    tax['pst_is_mandatory'] = false;
    //taxes.push(obj);
  }
  
  if(this.taxForm.value.hstCheck) {
    let obj = {};
    tax['is_hst'] = true;
    tax['hst'] = this.taxForm.value.hstValue;
    if(this.taxForm.value.hstManOpt == "man") {
      tax['hst_is_mandatory'] = true;
    }
    else {
      tax['hst_is_mandatory'] = false;
    }
    //taxes.push(obj);
  }
  
  if(!this.taxForm.value.hstCheck) {
    let obj = {};
    tax['is_hst'] = false;
    tax['hst'] = 0;
    tax['hst_is_mandatory'] = false;
    //taxes.push(obj);
  }
  
  if(this.taxForm.value.liquorCheck) {
    let obj = {};
    tax['is_liquor'] = true;
    tax['liquor'] = this.taxForm.value.liquorValue;
    if(this.taxForm.value.liquorManOpt == "man") {
      tax['liquor_is_mandatory'] = true;
    }
    else {
      tax['liquor_is_mandatory'] = false;
    }
    //taxes.push(obj);
  }
  
  if(!this.taxForm.value.liquorCheck) {
    let obj = {};
    tax['is_liquor'] = false;
    tax['liquor'] = 0;
    tax['liquor_is_mandatory'] = false;
  }

  tax['taxName1'] = this.taxForm.value.taxName1;
  tax['taxName2'] = this.taxForm.value.taxName2;
  tax['taxName3'] = this.taxForm.value.taxName3;
  tax['taxName4'] = this.taxForm.value.taxName4;

  finalObj['adminUser'] = adminUser;
  finalObj['course'] = course;
  finalObj['owners'] = ownersFinalArray;
  finalObj['menuSections'] = menu;
  finalObj['taxes'] = tax;
  finalObj['webapp'] = webapp;
// za  finalObj['vendor'] = vendor;
  //finalObj['vendorBlueSnapObj'] = merchantBlueSnapObj;
  if(this.course) {
    finalObj['id'] = this.course.id;
    //finalObj['course'].is_active = true;
    finalObj['course'].is_deleted = false;
    // console.log(finalObj);
    // console.log('Final Object of Course in update>>', finalObj);
    // console.log('Number of Locations;>>',  this.addUserForm.value.numberofLocations);
    // console.log('CourseLocations length>>', this.menuLocations.value.courseLocations.length);
     
    let TotalLocations = (this.addUserForm.value.numberofLocations) ? ( parseFloat(this.addUserForm.value.numberofLocations) + 3 ) : 3;
    // console.log('TotalLocations>>', TotalLocations);
    // console.log('TotalActiveMenues>>', TotalActiveMenues);

    if(TotalLocations < TotalActiveMenues)
    {
      this.alertService.createAlert("Active menu location/s exceed with additional plus default 3 ",0);
      return false;
    }

    this.dashboardService.updateCourse(finalObj).then(data => {
      if(data.success) {
        this.alertService.createAlert("Course updated successfully.",1);
        this.dialogRef.close('save');
      }
      else {
        this.alertService.createAlert(data.message,0);
      }
    })
  }
  else {
    //finalObj['course'].is_active = true;
    finalObj['course'].is_deleted = false;
    this.dashboardService.addCourse(finalObj).then(data => {
      if(data.success) {
        this.alertService.createAlert(data.message,1);
        this.dialogRef.close('save');
      }
      else {
        this.alertService.createAlert(data.message,0);
      }
    });
  }
}
  //Authenticate Stripe Account
  authStripeAccount() {
    let courseId = this.course.id;
    console.log('courseId: ', courseId);

    if(!courseId)
      return;
    let obj = {"courseId":courseId};
    this.dashboardService.authStripeAccount(obj).then(data => {
      console.log('Response: ', data);
      if(data.success) {
        console.log('Response: ', data);
        window.open(data.response);
      }
      else {
        
      }
    })
  }
  //webAppForm: FormGroup;
  fileInputLabel: string;

    onFileSelect(event) {
      const file = event.target.files[0];
      this.fileInputLabel = file.name;
      this.webAppForm.get('golfCourseLogo').setValue(file);
      if (!this.webAppForm.get('golfCourseLogo').value) {
        alert('Please fill valid details!');
        return false;
      } 
      const formData = new FormData();
      formData.append('golfCourseLogo', this.webAppForm.get('golfCourseLogo').value);
      this.http
      .post<any>(be_server+'uploadimage', formData).subscribe(data => {
        if(data.success) {
          this.webAppForm.controls['golfCourseLogo'].setValue(data.response);
          this.uploadedLogo = this.uploadPath+data.response;
        }
      }, er => {
        console.log(er);
        alert(er.error.error);
      });

    }
  //QR Code Generate
  qrCodeGenerate() {
    console.log('this.course.id: ', this.course.id);
    let courseId = this.course.id;
    if(!courseId)
      return;
    let obj = {"courseId":courseId};
    this.dashboardService.qrCodeGenerate(obj).then(data => {
      if(data.success) {
        console.log('Response: ', data);
        this.CourseQRCode = data.results
        this.course.qrCode = this.CourseQRCode;
      }
      else {
        
      }
    })
  }
  //QR Code Download
  qrCodeDownload(type) {
    let courseName = this.course.name;
    courseName = courseName.replace(/\s+/g, '-');
    courseName = courseName+'-QrCode';

    let courseId = this.course.id;
    let imagePath = this.course.qrCode;
    imagePath = imagePath.split(',');
    imagePath = imagePath[1];
    if(!courseId && imagePath)
      return;
    if(type == 'pdf'){
      var pdf = new jsPDF("p", "mm", "a4");
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      var imgData = 'data:image/jpeg;base64,'+imagePath;
      pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
      pdf.save(courseName+".pdf"); // download PDF
    }
    else{
      let  contentType = 'data:image/'+type+';base64,';
      const linkSource = contentType+imagePath;
      const downloadLink = document.createElement("a");
      const fileName = courseName+"."+type;
  
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }

   // console.log('download: ', download);
    // this.CourseQRCode = download;

    // this.dashboardService.qrCodeGenerate(obj).then(data => {
    //   if(data.success) {
    //     console.log('Response: ', data);
    //     this.CourseQRCode = data.results
    //   }
    //   else {
        
    //   }
    // })
  }
  generatePin(){
    let obj = {};
    obj['coursePin'] = this.menuLocations.value.coursePin;
    console.log('obj: ', obj);
    this.dashboardService.generatePin(obj).then(data => {
        if(data.success) {
          console.log('Response: ', data);
          console.log('data.results: ', data.results);
          this.menuLocations.controls['coursePin'].setValue(data.results);
          this.timeOut = '';
        }
        else if(this.course){
          this.timeOut = 'You did not change the PIN';
        }
        else{
          this.timeOut = 'PIN found in database, please regenerate the PIN';
        }
      })
  }
  // getTimeZone(){
  //   var x = 30; //minutes interval
  //   var times = []; // time array
  //   var tt = 0; // start time
  //   var ap = ['AM', 'PM']; // AM-PM

  //   for (var i=0; tt < 24*60; i++) {
  //     var hh = Math.floor(tt/60); // getting hours of day in 0-24 format
  //     var mm = (tt%60); // getting minutes of the hour in 0-55 format

  //     var name_time_cal = ((ap[Math.floor(hh/12)] == 'PM')?'-'+(("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2)):'+'+("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2))+' GMT';
      
  //     var value_time_cal = ((ap[Math.floor(hh/12)] == 'PM')?'-'+(("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2)):'+'+("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2));

  //     times.push({name: name_time_cal, value: value_time_cal});

  //     tt = tt + x;
  //   }

  //   return times;

  // }

  getTimeZone() {
    this.dashboardService.getAllTimeZone().then(response => {
      if(response.success) {
        this.time_zone = response.data
      }
    })
  }

  onTabPaymentSelect(){
    if(this.userType == 'admin'){
      this.taxForm.get('payment_type').setValidators([Validators.required]);
      this.taxForm.get('payment_type').updateValueAndValidity();
    }
    if (this.taxForm.value.payment_type == "later") {
      this.taxForm.get('payment_type_interval').setValidators([Validators.required]);
      this.taxForm.get('payment_type_interval').updateValueAndValidity();
    } else {
      this.taxForm.get('payment_type_interval').clearValidators();
      this.taxForm.get('payment_type_interval').updateValueAndValidity();
    }
  }
  onChangePaymentTypeInterval(){
    if(this.taxForm.value.payment_type_interval == null || this.taxForm.value.payment_type_interval == "null"){
      this.taxForm.patchValue({
        payment_type_interval: null
      });
    }
  }
}
