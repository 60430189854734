import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HostListener } from "@angular/core";
import { MatDialog } from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { AmountDivisionComponent } from './amount-division/amount-division.component';
import { DashboardService } from '../analytics/dashboard/dashboard.service';
import { FormControl } from '@angular/forms';
import { AlertService } from '../shared/services/alert.service';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
  // providers: [MatNativeDateModule, MatDatepickerModule]    
})
export class AnalyticsComponent implements OnInit {
  view: any[];
  constructor(
    public dialog: MatDialog,
    public dashboardService: DashboardService,
    private changeDetectorRefs: ChangeDetectorRef,
    private alertService: AlertService,
    public router: Router
  ) {
    /* if (localStorage.getItem('analytics_login') && localStorage.getItem('analytics_login') == "analytics") {
    } else {
      this.router.navigate(['/admin/login']);
    } */
    this.onResize();
    this.alwaysShowCalendars = true;

  }

  ngOnInit() {
    this.getAllCourses({});
    this.filterLeads();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.view = [(window.innerWidth / 100) * 90, (window.innerHeight / 100) * 45];
  }
  salesInfo: any = { "sales": 0, "salesAmount": 0, "cancelled": 0, "refund": 0 };
  single: any[];
  multi: any[] = [
    {
      name: 'Orders',
      series: []
    }
  ];

  usersList: any[] = [
    {
      name: 'Users',
      series: []
    }
  ];



  // options
  animations = true;
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = 'Dates';
  showYAxisLabel = false;
  yAxisLabel = 'Orders';
  timeline = true;
  filteredCourse = new FormControl();
  coursesList: any;
  selected: any = {start: moment().subtract(6, 'days'), end: moment()};
  alwaysShowCalendars: boolean;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }



  lineColorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  public colorScheme = {
    domain: ["#64C2A6", "#2D87BB", "#F66D44", "#FEAE65", "#E6F69D", "#AADEA7"]
  };
  public showLabels = true;
  public explodeSlices = false;
  public doughnut = false;

  pie1 = []

  pie2 = []

  // line, area
  autoScale = true;

  onSelect(event) {
    console.log(event);
  }

  onSelectPie(event) {
    console.log(event);
    console.log(this.pie2);
    let data: any = {};
    for (let i = 0; i < this.pie2.length; i++) {
      if (event.name == this.pie2[i]['name']) {
        data.tax = this.pie2[i]['tax_gst'] + this.pie2[i]['tax_pst'] + this.pie2[i]['tax_hst'] + this.pie2[i]['tax_liquor'];
        data.bluesnap = (this.pie2[i]['credit_fee'] + (this.pie2[i]['count'] * 0.30)).toFixed(2);
        data.nine_eighteen = this.pie2[i]['service_fee'];
        data.course = (this.pie2[i]['value'] - (this.pie2[i]['service_fee'] + data.tax + this.pie2[i]['tip'] + parseFloat(data.bluesnap))).toFixed(2);
        data.tip = this.pie2[i]['tip'];
        break;
      }
    }
    let dialogRef = this.dialog.open(AmountDivisionComponent, {
      data: data,
      height: 'auto',
      width: '300px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(dataReceived => {

    });
  }

  getAllCourses(obj) {
    this.dashboardService.getCourses(obj).then(data => {
      if (data.success) {
        this.coursesList = data.results;
      }
      else {
        this.coursesList = [];
        this.alertService.createAlert('Failed to fetch courses list.', 0);
      }
    });
  }

  selectAll(ev) {
    if (ev._selected && this.coursesList) {
      let temp = [];
      for (let i = 0; i < this.coursesList.length; i++) {
        temp.push(this.coursesList[i]["id"]);
      }
      this.filteredCourse.setValue(temp);

      ev._selected = true;
    }
    if (ev._selected == false) {
      this.filteredCourse.setValue([]);
    }
  }

  selectOne(ev) {
    this.coursesList.length <= this.filteredCourse.value.length && !ev._selected
      ? ev.select()
      : ev.deselect();
  }

  filterLeads() {
    console.log(this.selected);
    let filterObj = {};
    if (this.filteredCourse.value) {
      filterObj['courseId'] = this.filteredCourse.value;
    }

    if (this.selected && this.selected.start._d) {

      let date = new Date(this.selected.start._d);
      let day = (date.getDate() > 9 )? date.getDate() : date.getDate();
      let month = (date.getMonth() + 1 > 9 )? date.getMonth()+1 : date.getMonth()+1;
      let year = date.getFullYear();
      filterObj['startDate'] = year + '-' + month + '-' + day + ' 00:00:00';
    }

    if (this.selected && this.selected.end._d) {
      let date = new Date(this.selected.end._d);
      let day = (date.getDate() > 9 )? date.getDate() : date.getDate();
      let month = (date.getMonth() + 1 > 9 )? date.getMonth()+1 : date.getMonth()+1;
      let year = date.getFullYear();
      filterObj['endDate'] = year + '-' + month + '-' + day + ' 23:59:59';
    }

    console.log(filterObj, 'filters');
    this.getSalesInfo(filterObj);
    this.getOrdersByDate(filterObj);
    this.getSalesByCourse(filterObj);
    this.getSalesAmountByCourse(filterObj);
    this.getUsersByMonth(filterObj);
  }

  clear() {
    this.getSalesInfo({});
    this.getOrdersByDate({});
    this.getSalesByCourse({});
    this.getSalesAmountByCourse({});
    this.getUsersByMonth({});
  }


  getSalesInfo(obj) {
    this.dashboardService.getSalesInfo(obj).then(data => {
      if (data.success) {
        this.salesInfo = data.results;
        this.changeDetectorRefs.detectChanges();
      }
      else {
        this.salesInfo = { "sales": 0, "salesAmount": 0, "cancelled": 0, "refund": 0 };
      }
    });
  }

  getOrdersByDate(obj) {
    this.dashboardService.getOrdersByDate(obj).then(data => {
      if (data.success) {
        this.multi = [{ name: 'Orders', series: data.results }];
        this.changeDetectorRefs.detectChanges();
      }
      else {
        this.multi = [{ name: 'Orders', series: [] }];
      }
    });
  }

  getSalesByCourse(obj) {
    this.dashboardService.getSalesByCourse(obj).then(data => {
      if (data.success) {
        this.pie1 = data.results;
        this.changeDetectorRefs.detectChanges();
      }
      else {
        this.pie1 = [];
      }
    });
  }

  getSalesAmountByCourse(obj) {
    this.dashboardService.getSalesAmountByCourse(obj).then(data => {
      if (data.success) {
        this.pie2 = data.results;
        this.changeDetectorRefs.detectChanges();
      }
      else {
        this.pie2 = [];
      }
    });
  }

  getUsersByMonth(obj) {
    this.dashboardService.getUsersByMonth(obj).then(data => {
      if (data.success) {
        this.usersList = [{ name: 'Users', series: data.results }];
        this.changeDetectorRefs.detectChanges();
      }
      else {
        this.usersList = [{ name: 'Users', series: [] }];
      }
    });
  }

  logoutUser() {
      localStorage.removeItem('analytics_login');
      this.router.navigate(['/admin/login']);
  }


}
