import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {

  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string, sheetNames: any[]): void {

    let wb: XLSX.WorkBook = XLSX.utils.book_new();
    for(var i=0; i< sheetNames.length; i++) {
      let ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json[i]);
      XLSX.utils.book_append_sheet(wb, ws, sheetNames[i]);
    }
    console.log('worksheet',wb);
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

}