import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA , MatDialog } from '@angular/material';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { AlertService } from '../../../shared/services/alert.service';
import { DashboardService } from '../../../analytics/dashboard/dashboard.service';
import { Location, DatePipe } from '@angular/common';

@Component({
  selector: 'app-courselocation-dialog',
  templateUrl: './courselocation-dialog.component.html',
  styleUrls: ['./courselocation-dialog.component.scss']
})

export class CourselocationDialogComponent implements OnInit {

    isValidate = false;
    courseAddressForm: FormGroup;
    showCancel = true;
    //zipCords : any = [];
    message:String;
    userType = 'user';
    currDate = new Date();
    public maxDate = new Date(this.currDate.getFullYear(), this.currDate.getMonth(), this.currDate.getDate());
    clientIP:string;
  
  constructor(public datepipe: DatePipe, public dashboardService : DashboardService,public dialogRef: MatDialogRef<CourselocationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public course: any,private alertService: AlertService,public fb : FormBuilder
  ) {
    this.dialogRef.disableClose = true;
    this.createCourseLocationForm();
    if(this.course) {
      this.showCancel = this.course.canCancel ? true : false;
      this.getCourseData();
    } else {
      this.populateData();
    }
  } 
  getCourseData() {
    if(!this.course.courseId)
      return;
    this.dashboardService.getCourses({"courseId":this.course.courseId}).then(data => {
      if(data.success) {
        this.course = data.results[0];
        this.populateData();
      }
      else {
        this.course = {};
        this.alertService.createAlert(data.message,0);
      }
    })
  }

  createCourseLocationForm() {
    this.courseAddressForm = this.fb.group({
      name : new FormControl('', [Validators.required]),
      latitude : new FormControl('', [Validators.required]),
      longitude : new FormControl('', [Validators.required]),
      landmark : new FormControl('', [Validators.required])
    })
  }
  
  ngOnInit() {
    this.getClientIP();
    this.userType = localStorage.getItem("usertype");
  }

  getClientIP()  
  {
    this.dashboardService.getClientIPAddress().then(res => {
      this.clientIP=res.ip;
    });  
  } 

  populateData() {
    if(this.course) {
      console.log('course >> >> ',this.course);
      this.courseAddressForm.controls['name'].setValue(this.course.tbl_admin_users[0].state);
      this.courseAddressForm.controls['latitude'].setValue(this.course.tbl_admin_users[0].country);
      this.courseAddressForm.controls['longitude'].setValue(this.course.tbl_admin_users[0].city);
      this.courseAddressForm.controls['landmark'].setValue(this.course.tbl_admin_users[0].zip);
      if(this.course.geometry) {
        this.courseAddressForm.controls['latitude'].setValue(this.course.geometry.coordinates[0]);
        this.courseAddressForm.controls['longitude'].setValue(this.course.geometry.coordinates[1]);
      }
    }
    else {
      this.courseAddressForm.controls['name'].setValue('');
      this.courseAddressForm.controls['latitude'].setValue('');
      this.courseAddressForm.controls['longitude'].setValue('');
      this.courseAddressForm.controls['landmark'].setValue('');
    }
  }
  
  get name() {return this.courseAddressForm.get('name'); }
  get latitude() {return this.courseAddressForm.get('latitude'); }
  get longitude() {return this.courseAddressForm.get('longitude'); }
  get landmark() {return this.courseAddressForm.get('landmark'); }

  close(): void {
    this.dialogRef.close();
  }
//Add Course Location
  saveCourseLocation() {
    console.log('courseAddressForm: ',this.courseAddressForm.value);
    let course = {};
    let finalObj = {};
    // Create data for Course Location Table
      course['name'] = this.courseAddressForm.value.name;
      course['latitude'] = this.courseAddressForm.value.latitude;
      course['longitude'] = this.courseAddressForm.value.longitude;
      course['landmark'] = this.courseAddressForm.value.landmark;
      course['geometry'] = this.courseAddressForm.value.latitude + ' '+  this.courseAddressForm.value.longitude;
      finalObj['course'] = course;
    if(this.course) {
      finalObj['id'] = this.course.id;
      finalObj['course'].is_deleted = false;
      console.log(finalObj);
      console.log('Final Object of Course in update>>', finalObj);
      this.dashboardService.updateCourse(finalObj).then(data => {
        if(data.success) {
          this.alertService.createAlert("Course Location updated successfully.",1);
          this.dialogRef.close('save');
        }
        else {
          this.alertService.createAlert(data.message,0);
        }
      })
    }
    else {
      finalObj['course'].is_deleted = false;
      console.log('Final Object of Course Location add>>', finalObj);
      this.dashboardService.addCourse(finalObj).then(data => {
        if(data.success) {
          this.alertService.createAlert(data.message,1);
          this.dialogRef.close('save');
        }
        else {
          this.alertService.createAlert(data.message,0);
        }
      });
    }
  }
}
