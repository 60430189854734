import { Injectable, ViewChild } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Headers, RequestOptions } from '@angular/http';
import { Observable, BehaviorSubject } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';
import { TabsetComponent } from 'ngx-bootstrap';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    addcategoryurl = '/addcategory';
    getcategoriesurl = '/getcategoriesadmin';
    updateuserinfourl = '/updateuserinfo';
    getmenusectionsurl = '/getmenusections';
    getsalesinfourl = '/getsalesinfo';
    getordersbydateurl = '/getordersbydate';
    getsalesbycourseurl = '/getsalesbycourse';
    getsalesamountbycourseurl = '/getsalesamountbycourse';
    getusersbymonthurl = '/getusersbymonth';
    getitemsurl = '/getItems';
    getmenucategoriesurl = '/getmenucategories';
    additemurl = '/additem';
    updatecategoryurl = '/updatecategory';
    updateitemurl = '/updateitem';
    deleteitemurl = '/deleteitem';
    deletecategoryurl = '/deletecategory';
    updatemenustatusurl = '/updatemenustatus';
    updatemenutimingurl = '/updatemenutiming';
    getprovincesurl = '/getstates';
    addcourseurl = '/addcourse';
    getcoursesurl = '/getcourses';
    updatecourseurl = '/updatecourse';
    updatecoursestatusurl = '/updatecoursestatus';
    getmemberrequestsurl = "/getmemberrequests";
    updaterequesturl = "/upgradetomember";
    getcourseordersurl = "/getcourseorders";
    //getcoursepayoutsurl = "/getbluesnappayouts";
    getcoursepayoutsurl = "/getcoursepayout";
    generatememberinvoiceurl = "/generatememberinvoice";
    getsettingsurl = "/getsettings";
    updatesettingsurl = '/updatesettings';
    updatestatetaxesurl = '/updatestatetaxes';
    cancelSubscriptionUrl = '/cancel-subscription';
    getcountriesdropdownurl = '/getAllCountries';
    getcitiesdropdownurl = '/getcities';
    addtoppingsurl = '/addtoppings';
    gettoppingsurl = '/gettoppings';

    getauthtokenurl = '/getauthtoken';
    getlsconfigurl = '/getlsconfig';
    getlsmasterconfigurl = '/getlsmasterconfig';
    updatelsconfigurl = '/updatelsconfig';
    generatelstokenurl = '/generatelstoken';
    getpaymenttypeidurl = '/getpaymenttypeid';

    updateorderinstructionsurl = '/updateorderinstructions';
    authstripeaccounturl = '/authstripeaccount';
    uploadimageurl = '/uploadimage';
    getlocationssubscriptionurl = '/getlocationssubscription';
    updatelocationssubscriptionurl = '/updatelocationssubscription';
    getcourselocationsurl = '/getcourselocations';
    getqrcodeurl = '/getqrcode';
    updatestripeurl = '/updatestripe';
    generatepinurl = '/generatepin';
    getmenusectionsbyidurl = '/getmenusectionsById';
    getingredientsurl = '/getingredients';
    addingredientsurl = '/addingredients';
    getaddonsurl = '/getaddons';
    getnewaccesstoken = "/getnewaccesstoken";
    getlsRetailAccountID = '/getlsAccountID';
    updateRetaiSales = '/saveLightspeedConfig';
    getitemsidesurl = '/getitemsides';
    getAllTypeMessagesurl = '/getAllTypeMessages';
    updateCourseWiseMessagesUrl = '/updateCourseWiseMessages';
    getCardConnect = '/getCardConnect';
    addCardConnect = '/addCardConnect';

    sortCategoryOndrag = '/sortCategoryOndrag'
    updateSideListSort = '/sortSidesListOnDrag'
    updateItemSort = '/sortItemsListOnDrag';
    updateToppingListSort = '/sortToppingListOnDrag';

    getcoursepaymentmethod = '/getCoursePaymentMethod';

    insertOrderIntoForeUpGetItemIdUrl = '/insertOrderIntoForeUpGetItemId';
    updateorderitemintoforeupurl = '/updateOrderItemIntoForeUp';
    getforeupsettingsurl = '/getforeupsettings';
    getforeupsettingsusingapiurl = '/getforeupsettingsusingapi';

    getCoursePreBookingurl = '/getCoursePreBookings';
    
    getdriversurl = '/getdrivers';
    updatedriverurl = '/updatedriver';
    createdriverurl = '/createdriver';
    driverstatusurl = '/driverstatus';
    generatedriverpinurl = '/generatedriverpin';
    getAllTimeZoneurl = '/getAllTimeZone';

    updatemenulocationurl = '/updatemenulocation';
    updateRestoItems = '/saveLightspeedRestoItems';
    updateCourseForeupSetting = '/updateCourseForeupSettings';
    getCourseForeupSetting = '/getCourseForeupSettings';

    headers = new Headers({
        'Content-Type': 'application/json'
    });


    private messageSource = new BehaviorSubject('default message');
    currentMessage = this.messageSource.asObservable();

    constructor(private http: Http) { }

    addToppings(filter): Promise<any> {
        return this.http.post(this.addtoppingsurl, JSON.stringify(filter), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getToppings(filter): Promise<any> {
        return this.http.post(this.gettoppingsurl, JSON.stringify(filter), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getSettings(filter): Promise<any> {
        return this.http.post(this.getsettingsurl, JSON.stringify(filter), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    updateSettings(Obj): Promise<any> {
        return this.http.put(this.updatesettingsurl, JSON.stringify(Obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    updateStatesTax(Obj): Promise<any> {
        return this.http.put(this.updatestatetaxesurl, JSON.stringify(Obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    changeMessage(message: string) {
        this.messageSource.next(message)
    }

    addCategory(item): Promise<any> {

        return this.http.post(this.addcategoryurl, JSON.stringify(item), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    addCourse(finalObj): Promise<any> {

        return this.http.post(this.addcourseurl, JSON.stringify(finalObj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    updateCategory(item): Promise<any> {

        return this.http.post(this.updatecategoryurl, JSON.stringify(item), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    updateCourse(course): Promise<any> {

        return this.http.post(this.updatecourseurl, JSON.stringify(course), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    updateCourseStatus(course): Promise<any> {

        return this.http.post(this.updatecoursestatusurl, JSON.stringify(course), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    updateMenuStatus(type, item): Promise<any> {
        var data = {
            type: type,
            item: item
        };
        return this.http.post(this.updatemenustatusurl, JSON.stringify(data), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }
    updateMenuTiming(item): Promise<any> {
        var data = {
            item: item
        };
        return this.http.post(this.updatemenutimingurl, JSON.stringify(data), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    updateMenuLocation(item): Promise<any> {
        var data = {
            item: item
        };
        return this.http.post(this.updatemenulocationurl, JSON.stringify(data), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    updateItem(item): Promise<any> {

        return this.http.post(this.updateitemurl, JSON.stringify(item), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    addItem(item): Promise<any> {

        return this.http.post(this.additemurl, JSON.stringify(item), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    deleteItem(item): Promise<any> {

        return this.http.post(this.deleteitemurl, JSON.stringify(item), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    deleteCategory(category): Promise<any> {

        return this.http.post(this.deletecategoryurl, JSON.stringify(category), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    updateUserInfo(item): Promise<any> {

        return this.http.put(this.updateuserinfourl, JSON.stringify(item), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getCategories(item): Promise<any> {

        return this.http.post(this.getcategoriesurl, JSON.stringify(item), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getCourses(course): Promise<any> {

        return this.http.post(this.getcoursesurl, JSON.stringify(course), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    public getClientIPAddress()
    {
        return this.http.get("https://api.ipify.org/?format=json").toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    verifyAddress(postalCode) {
        let api_key = 'AIzaSyAgDUII_kvGfCJNmu4qhhzjl8YNzblV9Ng';
        return this.http.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + postalCode + "&sensor=false&key=" + api_key + "").map(function (res) {
            return res.json();
        });
    }

    getProvinces(province): Promise<any> {

        return this.http.post(this.getprovincesurl, JSON.stringify(province), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getCountriesDropdown(province): Promise<any> {

        return this.http.post(this.getcountriesdropdownurl, JSON.stringify(province), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getCitiesDropdown(province): Promise<any> {

        return this.http.post(this.getcitiesdropdownurl, JSON.stringify(province), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }


    getItems(item): Promise<any> {

        return this.http.post(this.getitemsurl, JSON.stringify(item), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getMemberRequests(item): Promise<any> {

        return this.http.post(this.getmemberrequestsurl, JSON.stringify(item), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getCourseOrders(item): Promise<any> {

        return this.http.post(this.getcourseordersurl, JSON.stringify(item), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getCoursePayouts(item): Promise<any> {

        return this.http.post(this.getcoursepayoutsurl, JSON.stringify(item), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    generateMemberInvoice(item): Promise<any> {

        return this.http.post(this.generatememberinvoiceurl, JSON.stringify(item), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    updateRequest(item): Promise<any> {

        return this.http.post(this.updaterequesturl, JSON.stringify(item), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getMenuSections(obj): Promise<any> {
        return this.http.post(this.getmenusectionsurl, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getMenuCategories(obj): Promise<any> {
        return this.http.post(this.getmenucategoriesurl, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    cancelSubscription(obj): Promise<any> {
        return this.http.post(this.cancelSubscriptionUrl, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getSalesInfo(obj): Promise<any> {
        return this.http.post(this.getsalesinfourl, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getOrdersByDate(obj): Promise<any> {
        return this.http.post(this.getordersbydateurl, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getSalesByCourse(obj): Promise<any> {
        return this.http.post(this.getsalesbycourseurl, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getSalesAmountByCourse(obj): Promise<any> {
        return this.http.post(this.getsalesamountbycourseurl, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getUsersByMonth(obj): Promise<any> {
        return this.http.post(this.getusersbymonthurl, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    generateAuth(obj): Promise<any> {
        return this.http.post(this.getauthtokenurl, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getLSSettings(obj): Promise<any> {
        return this.http.post(this.getlsconfigurl, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }
    getLSMasterSettings(obj): Promise<any> {
        return this.http.post(this.getlsmasterconfigurl, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    updateLightspeed(obj): Promise<any> {
        return this.http.post(this.updatelsconfigurl, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    generateLSToken(obj): Promise<any> {
        return this.http.post(this.generatelstokenurl, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getPaymentTypeId(obj): Promise<any> {
        return this.http.post(this.getpaymenttypeidurl, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    updateOrderInstructions(course): Promise<any> {
        return this.http.post(this.updateorderinstructionsurl, JSON.stringify(course), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    authStripeAccount(course): Promise<any> {
        return this.http.post(this.authstripeaccounturl, JSON.stringify(course), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    uploadImage(course): Promise<any> {
        return this.http.post(this.uploadimageurl, course, { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getLocationsSubscriptions(filter): Promise<any> {
        return this.http.post(this.getlocationssubscriptionurl, JSON.stringify(filter), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }
    updateLocationsSubscriptions(Obj): Promise<any> {
        return this.http.post(this.updatelocationssubscriptionurl, JSON.stringify(Obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }
    getCourseLocations(course): Promise<any> {

        return this.http.post(this.getcourselocationsurl, JSON.stringify(course), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }
    qrCodeGenerate(course): Promise<any> {
        return this.http.post(this.getqrcodeurl, JSON.stringify(course), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }
    updateStripeAccount(course): Promise<any> {
        return this.http.post(this.updatestripeurl, JSON.stringify(course), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }


    getMenuSectionsById(obj): Promise<any> {
        return this.http.post(this.getmenusectionsbyidurl, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    generatePin(obj): Promise<any> {
        return this.http.post(this.generatepinurl, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getIngredients(filter): Promise<any> {
        return this.http.post(this.getingredientsurl, JSON.stringify(filter), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }
    generateNewAccessToken(obj): Promise<any>{
        return this.http.post(this.getnewaccesstoken, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }
    getlsRetailAccountId(obj): Promise<any> {
        return this.http.post(this.getlsRetailAccountID, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }
    addIngredients(filter): Promise<any> {
        return this.http.post(this.addingredientsurl, JSON.stringify(filter), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }
    getAddons(filter): Promise<any> {
        return this.http.post(this.getaddonsurl, JSON.stringify(filter), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    updateRetaiSalesConfig(obj): Promise<any> {
        return this.http.post(this.updateRetaiSales, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }


    getItemSides(filter): Promise<any> {
        return this.http.post(this.getitemsidesurl, JSON.stringify(filter), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getAllTypeMessages(courseId): Promise<any> {
        return this.http.get(this.getAllTypeMessagesurl+"/"+courseId, { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    updateCourseWiseMessages(data): Promise<any> {
        return this.http.post(this.updateCourseWiseMessagesUrl, JSON.stringify(data), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }
    addFiserveCardConnect(data:any): Promise<any> {
        return this.http.post(this.addCardConnect, JSON.stringify(data), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }
    getFiserveCardConnect(data:any): Promise<any> {
        return this.http.post(this.getCardConnect, JSON.stringify(data), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    updateCategorySort(data:any): Promise<any> {
        return this.http.post(this.sortCategoryOndrag, JSON.stringify(data), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    updateSidesListSort(data:any): Promise<any> {
        return this.http.post(this.updateSideListSort, JSON.stringify(data), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }
    updateItemsSortOnDrag(data:any): Promise<any> {
        return this.http.post(this.updateItemSort, JSON.stringify(data), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    updateToppingsListSort(data:any): Promise<any> {
        return this.http.post(this.updateToppingListSort, JSON.stringify(data), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }




    private extractData(res: Response) {
        let body = res.json();
        return body || {};
    }
    private handleErrorPromise(error: Response | any) {

        return Promise.reject(error.message || error);
    }

    getCoursePaymentMethod(province): Promise<any> {
        return this.http.post(this.getcoursepaymentmethod, JSON.stringify(province), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    insertOrderIntoForeUpGetItemId(obj): Promise<any> {
        return this.http.post(this.insertOrderIntoForeUpGetItemIdUrl, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getForeUpSettings(obj): Promise<any> {
        return this.http.post(this.getforeupsettingsurl, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getForeUpSettingsUsingApi(obj): Promise<any> {
        return this.http.post(this.getforeupsettingsusingapiurl, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    updateOrderItemIntoForeUp(obj): Promise<any> {
        return this.http.put(this.updateorderitemintoforeupurl, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }


    getCoursePreBooking(item): Promise<any> {

        return this.http.post(this.getCoursePreBookingurl, JSON.stringify(item), { headers: this.headers, withCredentials: true }).toPromise()
        .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getDrivers(item): Promise<any> {

        return this.http.post(this.getdriversurl, JSON.stringify(item), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    updateDriver(obj): Promise<any> {
        return this.http.post(this.updatedriverurl, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    createDriver(obj): Promise<any> {
        return this.http.post(this.createdriverurl, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    driverStatus(obj): Promise<any> {
        return this.http.post(this.driverstatusurl, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    generateDriverPin(obj): Promise<any> {
        return this.http.post(this.generatedriverpinurl, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    getAllTimeZone(): Promise<any> {
        return this.http.post(this.getAllTimeZoneurl, { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    updateRestoItemConfig(obj): Promise<any> {
        return this.http.post(this.updateRestoItems, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }

    updateCourseForeupSettings(obj): Promise<any> {
        return this.http.post(this.updateCourseForeupSetting, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }
    getCourseForeupSettings(obj): Promise<any> {
        return this.http.post(this.getCourseForeupSetting, JSON.stringify(obj), { headers: this.headers, withCredentials: true }).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
    }
}